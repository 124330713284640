import React from "react";
import { Box } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery, useMutation } from "@apollo/client";
import ErrorIcon from "@material-ui/icons/Error";
import gql from "graphql-tag";
const GET_USER_QUERY = gql`
  query ($id: String!) {
    getUser(id: $id) {
      id
      favorites {
        id
      }
    }
  }
`;
const ADD_FAVORITE_MUTATION = gql`
  mutation ($estId: String!) {
    addFavorite(estId: $estId) {
      id
      favorites {
        id
      }
    }
  }
`;
export default function AddFavorite(props) {
  const { user, classes, estId } = props;
  const { data, loading, error } = useQuery(GET_USER_QUERY, {
    variables: {
      id: user.id,
    },
  });

  const [addFavorite, {loading: loadingMutation}] = useMutation(ADD_FAVORITE_MUTATION, {
    variables: { estId },
  });
  if (!loading && !loadingMutation) {
    if (error) {
      return (
        <Box mr={1}>
          <ErrorIcon className={classes.icon} />
        </Box>
      );
    }
    
    const usuario = data.getUser;
    if (usuario.favorites.find((est) => est.id === estId)) {
      return (
        <Box mr={1}>
          <FavoriteIcon onClick={addFavorite} className={classes.icon} />
        </Box>
      );
    } else {
      return (
        <Box mr={1}>
          <FavoriteBorderIcon onClick={addFavorite} className={classes.icon} />
        </Box>
      );
    }
  } else {
    return (
      <Box mr={1}>
        <CircularProgress />
      </Box>
    );
  }
}
