import React, { memo, useContext } from "react";

import {
  Grid,
  Box,
  Typography,
  isWidthUp,
  Button,
  Input,
  withStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import hostBack from "../../../config"
import {AuthContext} from "../../../context/auth"
const styles = (theme) => ({
  titulo: {
    fontSize: 30,
  },
  texto: {
    fontSize: 18,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
});
const RedButton = withStyles((theme) => ({
  root: {
    color: "#000",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "45px",
    padding: "1px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "0.8",
    },
  },
}))(Button);

function Inicial(props) {
  const { classes, width, busqueda, buscar } = props;
  const {ciudad} = useContext(AuthContext)
  const handleSubmit = (ev) => {
    ev.preventDefault();
    buscar();
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      p={6}
      className="lg-p-top lg-p-bottom"
    >
      <div>
        <Grid container spacing={isWidthUp("md", width) ? 10 : 5}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography align="center" className={classes.titulo} variant="h2">
              <span>ENCUENTRA LOS MEJORES PARCHES DE TU CIUDAD</span>
            </Typography>
            <br />
            <Typography align="center" className={classes.texto} paragraph>
              <span>
                Acá encontrarás los mejores planes para hacer en tu ciudad, si
                vas de viaje, si quieres salir con tus amigos, con tu pareja,
                con tus familiares y muchas opciones más. <br /> <br /> Descubre
                lo que tenemos para ti...
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.main} md={6} lg={6}>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Box display="flex" alignItems="flex-end">
                <Box mr={1} width="100%"  >
                  <Input
                    placeholder="Buscar establecimientos"
                    fullWidth
                    onChange={busqueda}
                    inputProps={{ "aria-label": "description" }}
                  />
                </Box>
                <RedButton size="small" type="submit">
                  <SearchIcon fontSize="large" />
                </RedButton>
              </Box>
            </form>

            <br />

            <img
              src={hostBack + `/images/ciudades/${ciudad}.jpg`}
              alt="Ciudad de demostración para la inclusión de busquedas de establecimientos en ciudades"
              width="100%"
              height="200px"
            />
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Inicial));
