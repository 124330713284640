import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  withStyles,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ColoredButton from "../../../shared/components/ColoredButton";

const styles = (theme) => ({
  termsConditionsListitem: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    "& ::before": {
      content: "-"
    }
  },
  dialogActions: {
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  backIcon: {
    marginRight: theme.spacing(1),
  },
});

function TermsOfServiceDialog(props) {
  const { classes, onClose, theme } = props;
  return (
    <Dialog open scroll="paper" onClose={onClose} hideBackdrop>
      <DialogTitle>TÉRMINOS Y CONDICIONES DE USO</DialogTitle>
      <DialogContent>
        <Typography align="justify" paragraph>
          PARCHATE S.A.S. es una sociedad constituida conforme a las leyes
          colombianas, identificada con NIT: 901385799-6, con domicilio en la
          ciudad de Bogotá D.C., que para estos términos operará y administrará
          la aplicación y/o plataforma web “Desparchate”.
        </Typography>
        <Typography align="justify" paragraph>
          Manifestamos que actuamos únicamente como intermediarios entre el/los
          usuarios y el/los clientes o personas llamadas a facilitar los
          servicios que se indican en nuestra página web, correspondiente a
          empresas Restaurantes, café y postres, Paintball, Futbol 5, Bolos,
          Karts, Parques de atracciones, Parques naturales, SPA, Bares,
          Discotecas, Cocteles, Nails, Trampolines, Museos, Casinos, Glamping,
          Camping, Turísticos, Rafting, Parapente, Canopy.
        </Typography>
        <Typography align="justify" paragraph>
          Por consiguiente, nuestra responsabilidad por las deficiencias en
          cualquiera de los servicios prestados está determinada en la ley 1480
          de 2011 referente al Estatuto del Consumidor. Igualmente, no tenemos
          injerencia en las decisiones o políticas de estos. Cualquier
          información adicional relativa a impuestos, condiciones, vigencias,
          tasas, cargos y demás pagos obligatorios deben ser consultados con
          cada uno de los clientes.
        </Typography>
        <Typography align="justify" paragraph>
          El uso de este sitio web, ratifica su acuerdo con los términos y
          condiciones expuestos a continuación. Por favor léalos cuidadosamente
          antes de hacer uso del sitio. Si no está de acuerdo con alguna sección
          del contenido, por favor envíe la solicitud a
          servicioalcliente@desparchate.com antes de utilizar este sitio web.
        </Typography>
        <Typography variant="h5" color="primary">
          DEFINICIONES
        </Typography>
        <br/>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Ejecutante:</strong> El ejecutante será el encargado de administrar, operar y
          manejar el funcionamiento de la aplicación móvil y/o la plataforma
          web. Quien para estos términos será Parchate SAS a menos que se
          designe una persona natural o jurídica diferente a esta.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Usuario(s):</strong> Los usuarios son todas aquellas personas, naturales o
          jurídicas, que utilicen la aplicación móvil y/o la página web con el
          fin de encontrar los establecimientos de entretenimiento que estén
          publicados en la aplicación y/o cualquier otro contenido publicado o
          expuesto en la misma.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Cliente(s):</strong> Los clientes de la aplicación móvil y/o
          la plataforma web son aquellas personas, naturales o jurídicas, que
          celebran un contrato vinculante con Parchate SAS; estos aceptan
          responder a las solicitudes de reserva que hagan los usuarios por
          medio de la plataforma, Los clientes obran de forma independiente y
          por cuenta o riesgo propio, librando así cualquier tipo de
          responsabilidad que pueda surgir al momento de prestar el servicio a
          los usuarios.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Mayor de edad:</strong> Persona natural que ha cumplido la
          mayoría de edad en territorio colombiano (18 años)
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Publicidad:</strong> Es la forma de comunicación que realiza
          el ejecutante con el objetivo de informar a los usuarios y/o clientes
          las ofertas, actividades, estrategias, productos o servicios, propios
          o de terceros.{" "}
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Servicio:</strong> Los servicios exhibidos en la aplicación
          móvil y/o página web como las reservas y ubicaciones, o cualquier otro
          servicio que se encuentre dentro de las mismas.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato personal:</strong> Cualquier información concerniente o
          vinculada a personas naturales o jurídicas determinadas o
          determinables.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Titular del dato personal:</strong> Persona natural cuyos
          datos son objeto de tratamiento. En el contexto de la presente
          política de tratamiento de datos personales los titulares podrán ser:
          (i) usuarios de la plataforma; (ii) clientes; (iii) proveedores (iv)
          todas aquellas personas no vinculadas a PARCHATE S.A.S. de quien se
          haga tratamiento de los datos personales.{" "}
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Base de datos personales:</strong> Conjunto organizado de
          datos personales que son objeto de tratamiento por una persona natural
          o jurídica.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato sensible:</strong> Es aquel dato personal que afecta la
          intimidad del Titular y cuyo uso incorrecto podría generar
          discriminación. Son considerados datos sensibles entre otros, los
          datos de salud, los datos de orientación sexual, origen racial y
          étnico, opiniones políticas, convicciones religiosas, filosóficas o
          morales.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato privado:</strong> Es aquel dato personal que por su
          carácter íntimo o reservado es relevante para el Titular.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato Semiprivado:</strong> Es aquel dato personal conocido y
          de interés tanto para el titular como para un determinado sector de
          personas o para la sociedad en general, por lo que no es de carácter
          íntimo, reservado o público.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato público:</strong> Es aquel dato personal calificado como
          tal según la Constitución y la ley, y que no se ha clasificado como
          dato personal privado o semiprivado.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Habeas data:</strong> Es el derecho que tiene el Titular de
          los datos personales de exigir de las administradoras de estos el
          acceso, inclusión, exclusión, corrección, adición, actualización y
          rectificación de los datos, así como la limitación en su divulgación,
          publicación o cesión.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Autorización:</strong> Consentimiento previo, expreso e
          informado del Titular para llevar a cabo el tratamiento de datos
          personales.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Aviso de privacidad:</strong> Comunicación verbal o escrita
          dirigida a los Titulares de los datos personales que están siendo
          tratados por la empresa, en la cual se le informa acerca de la
          existencia de las políticas de tratamiento de datos personales y la
          política de privacidad, que serán aplicadas, la forma de acceder a la
          mismas, y las finalidades para las cuales serán usados sus datos
          personales.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Responsable del tratamiento:</strong> Persona natural o
          jurídica de carácter público o privado que por sí misma o en asocio
          con otro u otros decide sobre el tratamiento de datos personales. En
          este caso, será PARCHATE S.A.S. el responsable del tratamiento.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Cookies:</strong> Las cookies son archivos que recogen
          información a través de una página web sobre los hábitos de navegación
          de un usuario o de su equipo y eventualmente podrían conformar una
          base de datos de acuerdo a la definición legal de la Ley 1581 de 2012
          al recolectar datos personales conforme a las siguientes
          características: (i) están referidos a aspectos exclusivos y propios
          de una persona natural, ii) permiten identificar a la persona, en
          mayor o menor medida, gracias a la visión de conjunto que se logre con
          el mismo y con otros datos; iii) su propiedad reside exclusivamente en
          el titular del mismo, situación que no se altera por su obtención por
          parte de un tercero de manera lícita o ilícita, y iv) su tratamiento
          está sometido a reglas especiales (principios) en lo relativo a su
          captación, administración y divulgación; caso en el cual, el
          responsable deberá ceñirse por las normas sobre protección de datos
          vigentes en Colombia, en especial la aplicación de los principios
          rectores para la administración de datos de legalidad, finalidad,
          libertad, veracidad o calidad, transparencia, acceso y circulación
          restringida, seguridad y confidencialidad consagrados en el artículo 4
          de la Ley 1581 de 2012.
        </Typography>
        <Typography color="primary" variant="h5">
          HABEAS DATA
        </Typography>
        <br/>
        <Typography align="justify" paragraph>
          Para dar cumplimiento con la ley 1266 de 2008 y la ley 1581 de 2012,
          Parchate SAS almacenará de forma virtual la información que el usuario
          y el cliente suministre por medio de los formularios pedidos. La
          información proveída por los clientes será de carácter pública con
          fines publicitarios y de promoción comercial en la aplicación;
          información que puedes conocer, actualizar y rectificar en cualquier
          momento. Por otro lado, la información privada como cámara de
          comercio, RUT, datos de contacto entre otros, será tratada de manera
          privada y Parchate SAS no estará en la capacidad de vender o poner a
          disposición de un tercero los datos brindados. Esta información
          privada será proporcionada una única vez y será almacenada de forma
          física en la oficina de Parchate SAS con domicilio en la ciudad de
          Bogotá. Puedes conocer, actualizar y rectificar esta información en
          cualquier momento. La información que registren los usuarios será de
          carácter privado, de igual forma puedes conocer, actualizar y
          rectificar esta información en cualquier momento, se almacenará de
          forma virtual por nosotros y no estaremos en la capacidad de vender a
          un tercero estos datos. La información como edad, país, ciudad y
          género será utilizada de forma anónima y no se conocerán los datos
          personales tales como nombre, apellido y e-mail. Entendemos que has
          leído los términos y condiciones y cualesquiera otros términos que te
          hayamos ofrecido, de esta forma y luego de aceptar estos términos,
          autorizas y apruebas a Parchate SAS para dar uso y tratar tus datos de
          acuerdo con lo leído en este documento.{" "}
        </Typography>
        <Typography color="primary" variant="h5">
          CONDICIONES GENERALES
        </Typography>
        <br/>
        <Typography variant="h6">Derechos de autor</Typography>
        <br/>
        <Typography align="justify" paragraph>
          El usuario, reconoce que todo el contenido de este sitio web, como
          marcas, logos, dibujos, slogan, que figuran en la web de
          www.desparchate.com, se encuentra protegida por el derecho de
          propiedad intelectual, expresamente reservado por www.desparchate.com
          o en su defecto, por las personas o empresas que figuran como autores
          o titulares de los derechos aquí contenidos. La transgresión de lo
          descrito será penada de conformidad con la legislación competente. Por
          lo anterior queda prohibida la reproducción, explotación, alteración,
          distribución o comunicación pública a cualquier título o medio de la
          totalidad del contenido en esta página o para uso diferente de los
          servicios ofertados, a menos que medie autorización o consentimiento
          previo y por escrito de www.desparchate.com
        </Typography>
        <Typography variant="h6">Seguridad y contraseñas</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Al registrarse en www.desparchate.com o mediante la aplicación móvil
          se asignará un usuario y deberá disponer de nombre y contraseña
          elegidos libremente, PARCHATE S.A.S. no se hace responsable del mal
          uso de las contraseñas que el Usuario pueda llevar a cabo como Usuario
          registrado al adquirir o reservar productos o servicios en el sitio
          web y/o aplicación móvil. Es responsabilidad de este proteger las
          claves y contraseñas que se suministren para el acceso como Usuario
          registrado, e impidiendo el uso indebido o acceso por parte de
          terceros.
        </Typography>
        <Typography align="justify" paragraph>
          El Usuario sólo podrá utilizar este sitio web para formalizar
          reservas, compras o pedidos de productos o servicios ofrecidos y no
          hará uso de este sitio web para realizar pedidos falsos o
          fraudulentos. El Usuario declara tener la mayoría de edad necesaria
          para establecer obligaciones jurídicamente vinculantes respecto de
          cualquier responsabilidad que pueda incurrir como resultado del uso de
          este sitio web. El Usuario se compromete a proporcionar información
          correcta y verdadera con relación a la utilización de este sitio web y
          acepta actualizar periódicamente sus datos para mantener actualizada
          la base de datos. Se prohíbe por ley realizar pedidos bajo nombre
          falso, seudónimo o alias indicando una forma de pago no válida. Se
          indica que, aunque suministre nombre ficticio, su navegador web
          transmite una dirección de internet (IP) única e inequívoca que las
          autoridades policiales podrán rastrear para identificarlo.
        </Typography>
        <Typography align="justify" paragraph>
          www.desparchate.com, se reserva el derecho de cancelar cualquier
          reserva o solicitud o cualquier otra transacción sobre cuya
          legitimidad tenga dudas razonables, incluyendo, sin limitación alguna,
          el uso ilegítimo o no autorizado de tarjeta de crédito o de débito.
        </Typography>
        <Typography variant="h6">Autonomía</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Estos términos y condiciones se rigen por la ley mercantil colombiana,
          y por su naturaleza jurídica no genera relación laboral entre las
          partes, ni tampoco genera relación de representación, de mandato, de
          agencia, de corretaje o de comisión entre las partes. Estos términos y
          condiciones dejan sin vigencia cualquier otro tipo de acuerdo o
          autorización que existiera entre las partes anteriormente sobre el
          mismo o similar objeto, pues las relaciones entre ellas se regirán
          exclusivamente por estos términos y condiciones en lo sucesivo.
        </Typography>
        <Typography variant="h5" color="primary">
          ADQUISICIÓN DE SERVICIOS
        </Typography>
        <br/>
        <Typography variant="h6">
          <br/>
          Ofertas de Suscripción disponibles{" "}
        </Typography>
        <Typography align="justify" paragraph>
          Ofrecemos diferentes planes de suscripción para los clientes, cada uno
          tiene las características detalladas en la página web
          www.desparchate.com
        </Typography>
        <Typography variant="h6">Forma</Typography>
        <br/>
        <Typography align="justify" paragraph>
          El usuario se compromete a pagar por adelantado y según corresponda la
          suscripción elegida al momento de iniciar el contrato con PARCHATE
          S.A.S.
        </Typography>
        <Typography variant="h6">Oferta Limitada </Typography>
        <br/>
        <Typography align="justify" paragraph>
          El usuario recibirá una prueba gratuita durante el primer mes de
          registro, este es un periodo de vigencia limitado sin costo alguno
          para que identifique las funcionalidades y el servicio que Desparchate
          está ofreciendo.
        </Typography>
        <Typography variant="h6">Pedidos</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Al solicitar o renovar la suscripción, el usuario acepta los detalles
          de la oferta para esa Suscripción. Acepta que ha leído y comprendido
          los detalles del servicio y conoce los deberes y derechos que tiene.
          De igual forma se compromete a pagar de forma oportuna, cumplir con
          todos los requerimientos y avisar, si es el caso, a la cancelación
          oportuna del servicio.
        </Typography>

        <Typography variant="h6">Precios y pago </Typography>
        <br/>
        <Typography align="justify" paragraph>
          Los pagos vencen y se deben efectuar de acuerdo con los detalles de la
          oferta para su suscripción. Durante el periodo de vigencia de la
          suscripción, los precios de los servicios no aumentarán, en cuanto a
          la suscripción, con relación a los publicados en el portal en el
          momento en que la suscripción entró en vigor o se renovó, salvo si los
          precios se identificaron como temporales en los detalles de la oferta.
          Todos los precios están sujetos a cambio al principio de la renovación
          de cualquier suscripción. Para todos los planes de suscripción, los
          precios estarán sujetos a cambio en cualquier momento previa
          notificación.
        </Typography>
        <Typography variant="h6">Renovación </Typography>
        <br/>
        <Typography align="justify" paragraph>
          Para las ofertas, la suscripción se renovará automáticamente para
          periodos de vigencia adicionales de un mes, hasta que finalice la
          suscripción. La suscripción es a término indefinido o hasta que el
          usuario decida terminar la suscripción. Le proporcionaremos una
          notificación de la renovación automática antes de la expiración del
          periodo de vigencia. Para las ofertas limitadas, podría no estar
          permitida la renovación.
        </Typography>
        <Typography variant="h6">Contrato de suscripción online</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Este contrato de suscripción online se celebra entre la persona
          jurídica que usted representa o usted personalmente (cliente) y el
          ejecutante (Parchate SAS). Este contrato está conformado por los
          términos y condiciones, los términos de los servicios, la política de
          privacidad y los detalles de la oferta para la Suscripción o
          renovación (en conjunto, el "contrato") aquí suscritos. Entrará en
          vigor en la fecha en que le proporcionemos la confirmación de su
          Suscripción o en la fecha en que se renueve su Suscripción, según
          corresponda.
        </Typography>
        <Typography variant="h6">Facturación de la suscripción</Typography>
        <br/>
        <Typography align="justify" paragraph>
          El cliente suscribirá factura de acuerdo con la suscripción elegida,
          para lo cual PARCHATE S.A.S la remitirá a través del correo
          electrónico suministrado, te enviaremos la factura correspondiente a
          la suscripción que hayas escogido. Podrás encontrarla en el correo
          electrónico que hayas diligenciado en los formularios de registro.
        </Typography>
        <Typography variant="h6">Derechos y deberes </Typography>
        <br/>
        <Typography align="justify" paragraph>
          Al momento de aceptar los términos y condiciones y cualquier convenio,
          es deber del usuario y del cliente enviar la información pedida a
          través de los formularios establecidos, con el objetivo de poder
          registrar e inscribir de la mejor manera toda la información
          suministrada. Si eres cliente, todos los datos como “descripción”,
          fotos, videos, “rango de precios”, ubicación, restricciones, contacto,
          entre otros datos, serán de dominio público y cualquier usuario de la
          aplicación podrá acceder a ellos en cualquier momento. La información
          suministrada tal como nombre, logo, descripción, fotos, entre otra
          información proporcionada a Parchate, será de propiedad del cliente,
          respetando así los derechos de propiedad intelectual. El usuario debe
          enviar todos los datos requeridos al momento de acceder a la
          plataforma y crear una cuenta o suscribir un contrato con PARCHATE
          S.A.S.{" "}
        </Typography>
        <Typography variant="h6">Terminación, vigencia y suspensión</Typography>
        <br/>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Periodo de vigencia y terminación del contrato.</strong> El
          presente contrato rige desde el momento de la suscripción y aceptación
          hasta su terminación o renovación de la Suscripción, lo que ocurra
          primero.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Terminación de la Suscripción.</strong> El cliente puede
          terminar unilateralmente la suscripción y/o contrato en cualquier
          momento o durante el periodo de vigencia. Sin embargo, debe pagar a
          PARCHATE S.A.S todos los importes causados y vencidos.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Suscripciones.</strong> Todas las suscripciones son a término
          indefinido, por periodo de mes a mes y de acuerdo con el plan
          adquirido. Cancelada la suscripción dentro de los treinta (30) días
          siguientes al inicio de esta, no se hará devoluciones o reembolsos. Si
          se cancela la suscripción o servicios contratados con PARCHATE S.A.S.
          en cualquier otro momento durante el periodo de vigencia, deberá pagar
          el término restante de dicho periodo y no recibirá reembolso alguno.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Ofertas Limitadas.</strong> PARCHATE S.A.S podrá rescindir la
          Suscripción u Oferta Limitada en cualquier momento durante el Plazo
          inmediatamente y sin previo aviso.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Suspensión.</strong> PARCHATE S.A.S podrá suspender el uso de los servicios
          adquiridos bajo las siguientes condiciones, si: (1) es razonablemente
          necesario para evitar el acceso no autorizado a los datos del cliente;
          (2) si el cliente no responde a una reclamación de presunta infracción
          de derechos dentro del término de treinta (30) días calendario; (3) si
          no paga las valores adeudadas estipuladas en el contrato; (4) si no
          cumple con la Política de Uso Aceptable o infringe otros términos de
          este contrato; o (5) para Ofertas Limitadas, la Suscripción queda
          inactiva debido a que usted no puede acceder a los Servicios Online
          como se describe en los Detalles de la Oferta. Si se presentaran una o
          más de estas condiciones, entonces: (i) Para las Ofertas Limitadas,
          podemos suspender el uso que usted hace de los Servicios Online, o
          suspender su Suscripción y su cuenta de inmediato, sin notificación
          previa. (ii) Para todas las demás Suscripciones, se aplicará una
          suspensión a la parte necesaria mínima de los Servicios Online y solo
          estará en vigor mientras exista la condición o la necesidad. Le
          enviaremos una notificación antes de efectuar la suspensión, excepto
          en caso de que consideremos que es necesario efectuarla
          inmediatamente. Le notificaremos con treinta (30) días antes de una
          suspensión por no pago. Si no se ocupa completamente de las razones de
          la suspensión en un plazo de sesenta (60) días después de la
          suspensión, podemos terminar su Suscripción y eliminar sus Datos del
          Cliente sin periodo de retención. También podemos terminar su
          Suscripción si el uso que hace de los Servicios Online se suspende más
          de dos veces en un periodo de doce (12) meses. PARCHATE S.A.S y/o
          www.desparchate.com se reserva el derecho, a su discreción, de
          modificar, suspender o extinguir el presente sitio web o apartes del
          mismo, incluidos los servicios y productos disponibles a través de
          este sitio web, así como su perfil, la contraseña de su Cuenta, o el
          uso del Sitio o de cualquier parte del mismo, en cualquier momento y
          por la razón que estime oportuna, y podrá notificarlo o no. En caso de
          extinción, seguirá el Usuario vinculado por las obligaciones que ha
          asumido con arreglo al presente sitio web y a sus términos
          adicionales, incluido las garantías que hubiese ofrecido el Usuario, y
          de su responsabilidad. Igualmente PARCHATE S.A.S y/o
          www.desparchate.com no será responsable ante el Usuario, ni ante
          ningún tercero por el retiro o el acceso a este sitio web.
        </Typography>
        <Typography variant="h6">
          <br/>
          Pruebas y modificaciones en el servicio
        </Typography>
        <Typography align="justify" paragraph>
          Para mejorar el servicio prestado por PARCHATE S.A.S, podemos ofrecer
          pruebas de suscripciones que pueden ser gratis o pagadas en un periodo
          especifico de tiempo. Estas pruebas serán notificadas días antes del
          lanzamiento oficial de la prueba con el fin de verificar si son
          aceptadas o rechazadas. De ser aceptadas, te comprometes a, si es el
          caso, pagar el monto extra cobrado por el servicio de prueba prestado
          en el tiempo específico previamente notificado. Si es rechazado,
          seguirá el cobro normal por el servicio adquirido sin aumentos en las
          tarifas. Podremos modificar el servicio con el fin de presentar
          mejoras respecto a las funcionalidades, la parte gráfica, la parte
          operativa y/o cualquier otra modificación en la aplicación para tener
          un mejor rendimiento y prestar un mejor servicio.
        </Typography>
        <Typography variant="h6">Exoneración de garantías</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Cualquier clase de contenido, entre los que se incluye programas
          informáticos, productos, información, textos, servicios y gráficos
          indicados a través del presente sitio web, se suministran para su
          facilidad y disponibilidad. PARCHATE S.A.S. no ofrece ninguna clase de
          garantías en cuanto a información, contenidos o al funcionamiento del
          presente sitio web www.desparchate.com no se compromete ni garantiza
          que este sitio web funcione sin errores o de forma ininterrumpida, por
          defectos en sus servidores, transmisores, o conexiones defectuosas, o
          fallas técnicas. Igualmente www.desparchate.com no ofrece garantía
          respecto al precio ofrecido por productos o servicios disponibles a
          través de este sitio web, o por aceptación de cualquier reserva, o a
          la disponibilidad de productos o servicios a través de este sitio web,
          o por el mal uso de este sitio. La aplicación y/o el sitio web es un
          medio para conectar los establecimientos de entretenimiento (clientes)
          con los usuarios que utilicen la aplicación, sirve únicamente como
          medio de visualización. Por lo tanto, no nos hacemos responsables de
          las decisiones que tomen tanto los clientes como los usuarios de la
          aplicación. Tampoco nos hacemos responsables por las acciones y/o
          inconvenientes que ocurran entre clientes y usuarios.
        </Typography>
        <Typography variant="h6">Limitación de responsabilidad</Typography>
        <br/>
        <Typography align="justify" paragraph>
          PARCHATE S.A.S incluyendo a sus directivos, empleados, representantes,
          distribuidores, proveedores, agentes, o demás personas en la creación,
          patrocinio, promoción o cualquier aporte a la disponibilidad de este
          sitio y de su contenido, no será responsable ante ninguna persona o
          entidad por ningún daño directo, indirecto o caso fortuito o en los
          siguientes; a.- Por la Interrupción, pérdida de datos, daño producido
          en su ordenador o sistema informático o por cualquier otro sitio web
          al que haya accedido mediante hipervínculos desde este sitio web; b.-
          Por la no autorización estatal del funcionamiento de este sitio web;
          c.- Por el acceso no autorizado a sus datos personales; d.- Por la
          imposibilidad de suministrar un servicio; e.- Por cualquier clase de
          error o inexactitud que pueda contener el presente sitio web; f.- Por
          cualquier transacción comercial realizada a través de este sitio web.
        </Typography>
        <Typography align="justify" paragraph>
          Se entiende que los proveedores de servicios son contratistas
          independientes y no agentes o empleados de las partes amparadas. Las
          partes amparadas no asumen responsabilidad alguna por lesiones, daños,
          fallecimiento, pérdidas, accidentes o retrasos que se produzcan por
          razón de actos y/o omisiones de un proveedor de servicios, o por
          acciones en que concurra la culpa o dolo de un proveedor de servicios
          turísticos. Las Partes amparadas no serán responsables del
          incumplimiento por parte de los Proveedores de servicios, de las
          garantías, incluidas las garantías implícitas de aptitud para un fin
          determinado o comerciabilidad, ni tampoco responderán las Partes
          amparadas de cualquier otra infracción cometida por el Proveedor de
          servicios, en cuanto a productos o servicios disponibles a través de
          este sitio web. Las Partes amparadas no serán en ningún caso
          responsable del posible incumplimiento por parte del Proveedor de este
          sitio web o de ninguna ley estatal, o Departamental. Si, a pesar de lo
          dicho anteriormente, una parte amparada fuese declarada responsable de
          cualquier pérdida o daño relacionado con al uso de este sitio web, el
          Usuario acepta que la responsabilidad de cualquiera de estas partes no
          superará en ningún caso el cargo total al Usuario valorado por
          www.desparchate.com al realizar una reserva.
        </Typography>
        <Typography variant="h6">Garantía de indemnidad</Typography>
        <br/>
        <Typography align="justify" paragraph>
          La persona que haga uso en cualquier modalidad de este sitio web o
          aplicación móvil, mantendrá indemne a PARCHATE S.A.S y/o
          www.desparchate.com y a cualquier persona que tenga relación con esta
          página, frente a cualquier reclamación de carácter administrativo,
          civil, penal, multas, penalizaciones, gastos de cualquier naturaleza
          por; incumplimiento de este sitio web, por el indebido uso del sitio
          web, o por violación a las leyes o derechos de terceros.
        </Typography>
        <Typography variant="h6">Efectividad de la reserva</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Las solicitudes o reservas que el Usuario realice se otorgaran dentro
          de los plazos establecidos en el sitio web, a menos que existan
          errores en las direcciones de correos electrónicos o datos equivocados
          suministrados por el Usuario, y no será responsabilidad ni de PARCHATE
          S.A.S y/o www.desparchate.com y mucho menos de los Proveedores de
          Servicios ofertados.
        </Typography>
        <Typography variant="h6">Enlaces a otros sititos webs</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Este sitio web contiene enlaces a servicios y recursos externos, las
          consultas relativas a dichos servicios o recursos deberán dirigirse al
          proveedor de dicho servicio o recurso externo en particular. PARCHATE
          S.A.S y/o www.desparchate.com no hace seguimiento ni control de los
          sitios web enlazados ni ofrece manifestaciones o garantías, ni asume
          responsabilidad alguna, por la exactitud, fiabilidad o disponibilidad
          de los contenidos cargados, mostrados o distribuidos, o por los
          productos o servicios disponibles, en dichos sitios web. Si el Usuario
          y/o cliente opta por acceder al sitio web de un tercero, lo hace a su
          propio riesgo.{" "}
        </Typography>
        <Typography variant="h6">Errores en los precios</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Nuestros Proveedores de servicios nos proporcionan los precios y demás
          información relativa a los servicios. Si alguno de los servicios
          relacionados o proporcionados viniera con un precio incorrecto o con
          información incorrecta debido a errores tipográficos o se produjera
          cualquier otro error en los precios o en la información del servicio
          que recibamos de nuestros proveedores de servicios, nos reservamos el
          derecho de rechazar o cancelar las reservas de dicho servicio.{" "}
        </Typography>

        <Typography variant="h6">Notificación electrónica</Typography>
        <br/>
        <Typography align="justify" paragraph>
          En la medida que tengamos necesidad de ponernos en contacto con el
          Cliente y/o Usuario, acepta que lo realicemos por medios electrónicos,
          incluyendo, a título meramente enunciativo y no limitativo, mediante
          comunicaciones publicadas en este sitio web, por correo electrónico o
          mediante la web y/o aplicación móvil.
        </Typography>
        <Typography variant="h6">Uso internacional</Typography>
        <br/>
        <Typography align="justify" paragraph>
          En determinados países ciertas personas no están autorizadas por ley a
          acceder a los materiales de este sitio web. PARCHATE S.A.S no indicará
          si los materiales de este sitio web son adecuados o están disponibles
          para su uso en lugares fuera de Colombia. Si es Usted residente en uno
          de estos países, no deberá realizar transacciones comerciales en este
          sitio web.
        </Typography>
        <Typography variant="h6">Hipervínculos</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Queda rotundamente prohibido a menos que se autorice, los sitios web
          no podrán establecer hipervínculos a ninguna página que no sea la
          página principal de este sitio web, ni a ningún sitio web o material
          contenido en el mismo, ni tampoco podrá ninguna entidad establecer
          hipervínculos con ningún aspecto de este sitio web en correos
          electrónicos con fines comerciales, sin el previo consentimiento
          expreso y por escrito de PARCHATE S.A.S.
        </Typography>
        <Typography variant="h6">Derecho de Retracto </Typography>
        <br/>
        <Typography align="justify" paragraph>
          PARCHATE S.A.S en aplicación al derecho de RETRACTO que tiene el
          usuario, en ventas por Internet, de conformidad con el artículo 47 de
          la ley 1480 de 2011. El cliente podrá solicitar el retracto o
          desistimiento respecto del contrato de venta del bien o servicio
          adquirido dentro del término de cinco (5) días que por su naturaleza
          no deban consumirse o no hayan comenzado a ejecutarse, se entenderá
          pactado el derecho de retracto por parte del consumidor, en el evento
          en que se haga uso de la facultad de retracto previa comunicación
          dirigida a PARCHATE S.A.S; cumplido este requisito se resolverá el
          contrato y se deberá reintegrar el dinero que el consumidor hubiese
          pagado.
        </Typography>
        <Typography variant="h6">
          <br/>
          Aceptación de términos y condiciones
        </Typography>
        <Typography align="justify" paragraph>
          La presente aceptación de términos y condiciones para con la empresa
          PARCHATE SAS y/o por medio de la aplicación Desparchate,
          www.desparchate.com , en calidad de usuario o cliente es expresa y
          reconocen que ha sido leída y aceptada de manera libre, voluntaria y
          espontánea. Aceptas estos convenios y estás de acuerdo en vincularte a
          los mismos. Si no estás de acuerdo o no puedes cumplir dichos
          términos, no podrás usar ni acceder a los servicios ofrecidos. Para
          poder acceder a los servicios que tenemos para ti, debes tener la
          capacidad de celebrar un contrato vinculante con nosotros y no estar
          impedido para hacerlo bajo cualquier legislación aplicable. Toda la
          información de registro que nos envíes debe ser verdadera, completa y
          especifica según los requerimientos establecidos en los formularios
          proporcionados, además estás de acuerdo en mantener esta información
          así en todo momento. En caso de cambios en la información brindada,
          deberás comunicarla con anterioridad para que sea actualizada.
        </Typography>
        <Typography variant="h6">Reserva de derecho de admisión</Typography>
        <br/>
        <Typography align="justify" paragraph>
          PARCHATE S.A.S se reserva el derecho a rechazar la admisión,
          publicación o transmisión de cualquier contenido a su discreción o
          criterio, al igual que no se hace responsable por el derecho de
          admisión que pueda adoptar o no cada uno de sus proveedores, clientes
          o terceros sobre los servicios ofertados, en especial al ingreso de
          los usuarios a los bienes de dominio público o privado.
        </Typography>
        <Typography variant="h6">
          <br/>
          Modificaciones en los términos y condiciones
        </Typography>
        <Typography align="justify" paragraph>
          Para poder brindarte una mejor forma de conexión con los usuarios,
          podemos realizar cambios en los convenios por razones como mejorar las
          funciones, agregar funciones o características al servicio, realizar
          ajustes técnicos razonables con el fin de garantizar la operabilidad
          y/o la seguridad del servicio, y por razones legales o regulatorias
          sin previo aviso.
        </Typography>
        <Typography variant="h6">Atención al cliente</Typography>
        <br/>
        <Typography align="justify" paragraph>
          En caso de presentar inconvenientes o inconformidades con el servicio,
          podrás ponerte en contacto con nosotros, enviando un correo
          electrónico especificando tus datos, tus preguntas o inconvenientes.
          Nos comprometemos a responder y a realizar todos los esfuerzos
          necesarios para dar una solución a tus preguntas. También nos
          comprometemos a responder dentro del término de diez (10) días hábiles
          siguientes a la recepción de la solicitud.{" "}
        </Typography>
        <Typography variant="h6">Contacto</Typography>
        <br/>
        <Typography align="justify" paragraph>
          Si necesitas contactarte con nosotros para recibir información sobre
          las suscripciones, los beneficios, las categorías o cualquier otra
          información relacionada con nuestros servicios o P.Q.R. puedes
          enviarnos la solicitud al correo electrónico info@desparchate.com
        </Typography>
        <Typography align="justify" paragraph>
          Si tienes problema con tu cuenta, con el funcionamiento, con problemas
          técnicos o cualquier otro inconveniente puedes enviarnos un mensaje a
          servicioalcliente@desparchate.com
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ColoredButton
          onClick={onClose}
          variant="contained"
          color={theme.palette.common.black}
        >
          <ArrowBackIcon className={classes.backIcon} />
          Atras
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}

TermsOfServiceDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TermsOfServiceDialog);
