import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography, Card, Box, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  img: {
    width: "100%",
    height: "250px",
  },
  card: {
    boxShadow: theme.shadows[2],
    height: "auto",
    borderBottom: "3px solid " + theme.palette.common.darkGray,
    borderRadius: 20
  },
  noDecoration: {
    textDecoration: "none !important",
  },
  title: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    color: theme.palette.common.black,
    textAlign: "center"
  },
  showFocus: {
    "&:focus span": {
      color: theme.palette.secondary.dark,
    },
  },
});

function BlogCard(props) {
  const { classes, url, src, title, } = props;

  return (
    <Card sx={{ heigth: "250px" }} className={classes.card}>

      <Link to={url}><img src={src} className={classes.img} alt="" /></Link>
      <Box p={2} >

        <Typography align="center" variant="h6">
          <span className={classes.title}>{title}</span>
        </Typography>
      </Box>
    </Card>
  );
}

BlogCard.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  src: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(BlogCard);
