import React, { memo, useContext } from "react";
import { useQuery, gql } from "@apollo/client";
import { Box, Typography, withStyles, Grid } from "@material-ui/core";
import Nothing from "../../../errors/Nothing";
import Loading from "../../../errors/Loading";
import hostBack from "../../../config";
import { AuthContext } from "../../../context/auth";
import { Link, Redirect } from "react-router-dom";

const styles = (theme) => ({
  titulo: {
    fontSize: "1.45em",
  },
  img: {
    borderRadius: 18,
    width: "100%",
    userDrag: "none",
  },
  caja: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "12px",
  },
  principal: {
    fontSize: "2.50em",
  },
});

const GET_USER_QUERY = gql`
  query ($id: String!) {
    getUser(id: $id) {
      id
      favorites {
        id
        principalImage
        name
      }
    }
  }
`;

function Categoria(props) {
  const { classes } = props;
  const { user } = useContext(AuthContext);

  const { data, loading, error } = useQuery(GET_USER_QUERY, {
    variables: { id: user.id },
  });

  if (loading) return <Loading min={true} topPad={true} />;
  if (error)
    return <Nothing text={`Error! ${error.message}`} top={true} min={true} />;
    const actualUser = data.getUser;
    
  if (user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        p={6}
        style={{ minHeight: "61.3vh" }}
        pt={12}
        className="lg-p-bottom "
      >
        <div style={{ width: "100%" }}>
          <Typography align="center" variant="h2" className={classes.principal}>
            Favoritos
          </Typography>
          <br />
          <Grid container>
            {actualUser.favorites.length > 0 ? (
              actualUser.favorites.map((establecimiento) => (
                <Grid
                  key={establecimiento.id}
                  item
                  lg={2}
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.caja}
                >
                  <Link
                    to={`/establecimientos/${establecimiento.id}`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "85%",
                    }}
                  >
                    <img
                      src={hostBack + establecimiento.principalImage}
                      alt="Ciudad de demostración para la inclusión de busquedas de establecimientos en ciudades"
                      className={classes.img}
                      height="200px"
                    />
                  </Link>
                  <Typography
                    align="center"
                    className={classes.titulo}
                    variant="h3"
                  >
                    {establecimiento.name}
                  </Typography>
                </Grid>
              ))
            ) : (
              <Nothing
                fullHeight={true}
                top={true}
                text="Aun no has añadido ningún establecimiento a tus favoritos"
              />
            )}
          </Grid>
        </div>
      </Box>
    );
  } else {
    return <Redirect to="/" />;
  }
}

export default withStyles(styles, { withTheme: true })(memo(Categoria));
