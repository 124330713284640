import React, {
  useState,
  useCallback,
  Fragment,
  memo,
  forwardRef,
} from "react";
import {
  TextField,
  Button,
  withStyles,
  Dialog,
  Slide,
  DialogContent,
  Typography,
  DialogTitle,
  Box,
  DialogActions,
} from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { DropzoneArea } from "material-ui-dropzone";
import HighlightedInformation from "../shared/components/HighlightedInformation";
import DatePicker, { registerLocale } from "react-datepicker";
import { addDays } from "date-fns";
import es from "date-fns/locale/es";
import Nothing from "../errors/Nothing";
import Loading from "../errors/Loading";

const MAKE_EVENTO_MUTATION = gql`
  mutation (
    $finalDate: String!
    $foto: Upload!
    $title: String!
    $message: String!
  ) {
    makeEvento(
      foto: $foto
      title: $title
      message: $message
      finalDate: $finalDate
    ) {
      foto
      message
      title
      createdAt
      finalDate
      establecimiento {
        id
        name
      }
    }
  }
`;
const GET_ESTEVENTS_QUERY = gql`
  {
    getEstEvento {
      id
      foto
      message
      title

      establecimiento {
        id
        name
      }
      createdAt
      finalDate
    }
  }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "56px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "0.8",
    },
  },
}))(Button);

const styles = (theme) => ({
  borderMinus: {
    borderRadius: 14,
  },
});

function MakeEvento(props) {
  const { classes, callback } = props;
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const { data, loading, error } = useQuery(GET_ESTEVENTS_QUERY);
  const [newDesc, setNewDesc] = useState({
    foto: null,
    title: "",
    message: "",
    finalDate: addDays(new Date(), 1),
  });
  const [editDescripcion, { loading: loaginMutation }] = useMutation(
    MAKE_EVENTO_MUTATION,
    {
      variables: { ...newDesc },
      onCompleted: () => callback(false),
      onError: (err) =>
        setErrors({
          ...errors,
          ...err.graphQLErrors[0].extensions.errors,
        }),
    }
  );

  const handleChange = useCallback(
    (ev) => {
      if (ev.target.validity.valid) {
        setNewDesc({ ...newDesc, [ev.target.name]: ev.target.value });
      }
    },
    [setNewDesc, newDesc]
  );
  const handleSubmit = () => {
    editDescripcion();
  };
  registerLocale("es", es); // register it with the name you want
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <RedButton className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </RedButton>
  ));

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => callback()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Inicie evento </DialogTitle>

      <DialogContent>
        {loading ? (
          <Loading min={true} />
        ) : error ? (
          <Nothing text={`Error! ${error.message}`} min={true} />
        ) : data.getEstEvento ? (
          <Nothing
            text={`Ya ha creado un evento, para iniciar otro debe esperar a que finalice el anterior`}
            min={true}
          />
        ) : (
          <Fragment>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h5">Fecha de finalización</Typography>
              <DatePicker
                open={open}
                dateFormat="dd/MM/yyyy"
                showPopperArrow={false}
                selected={newDesc.finalDate}
                onCalendarOpen={() => setOpen(true)}
                onCalendarClose={() => setOpen(false)}
                minDate={addDays(new Date(), 1)}
                maxDate={addDays(new Date(), 5)}
                onChange={(date) => setNewDesc({ ...newDesc, finalDate: date })}
                customInput={<ExampleCustomInput />}
                locale="es"
              />
            </Box>
            <br />
            <DropzoneArea
              acceptedFiles={["image/*"]}
              dropzoneText={"Subir la imagen de su servicio"}
              onChange={(files) => setNewDesc({ ...newDesc, foto: files[0] })}
              filesLimit={1}
              showPreviewsInDropzone={true}
            />
            <br />
            <br />

            <TextField
              variant="filled"
              required
              multiline
              className={classes.whiteInput}
              fullWidth
              onChange={handleChange}
              value={newDesc.title}
              InputLabelProps={{
                shrink: true,
              }}
              id="title"
              label="Titulo"
              name="title"
            />
            <br />
            <br />
            <TextField
              variant="filled"
              required
              multiline
              className={classes.whiteInput}
              fullWidth
              onChange={handleChange}
              value={newDesc.message}
              InputLabelProps={{
                shrink: true,
              }}
              id="message"
              label="Descripción del evento"
              rowsMax={3}
              name="message"
            />

            {Object.keys(errors).length !== 0 && (
              <Fragment>
                <br />
                <br />

                <HighlightedInformation>
                  {Object.values(errors).map((err, i, array) => {
                    return (
                      <Fragment key={i}>
                        {`*${err}`} <br />{" "}
                        {array.length - 1 !== i ? <br /> : ""}
                      </Fragment>
                    );
                  })}
                </HighlightedInformation>
              </Fragment>
            )}
          </Fragment>
        )}
      </DialogContent>
      {!error && !loading && data && (
        <DialogActions>
          <RedButton
            className={classes.borderMinus}
            onClick={() => callback(false)}
            color="default"
          >
            Cancelar
          </RedButton>
          <RedButton
            className={classes.borderMinus}
            onClick={handleSubmit}
            color="default"
            autoFocus
            disabled={!newDesc.foto || loaginMutation}
          >
            Crear
          </RedButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
export default withStyles(styles, { withTheme: true })(memo(MakeEvento));
