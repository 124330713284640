import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, isWidthUp, withWidth, withStyles } from "@material-ui/core";
import BlogCard from "./BlogCard";
import { useQuery, gql } from "@apollo/client"
import hostBack from "../../../config"
import Nothing from "../../../errors/Nothing";
import Loading from "../../../errors/Loading";
const FENTCH_PLANS_QUERY = gql`
{
  getPlanes{
    id
    mainPlan
    mainFoto
  }
}
`

const styles = (theme) => ({
  blogContentWrapper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    maxWidth: 1280,
    width: "100%",
  },
  wrapper: {
    minHeight: "60vh",
    backgroundColor: theme.palette.common.ligthGray
  },
  noDecoration: {
    textDecoration: "none !important",
  },
});

function getVerticalBlogPosts(width, blogPosts) {
  const gridRows = [[], [], []];

  let rows;
  let xs;
  if (isWidthUp("md", width)) {
    rows = 3;
    xs = 4;
  } else if (isWidthUp("sm", width)) {
    rows = 2;
    xs = 6;
  } else {
    rows = 1;
    xs = 12;
  }
  blogPosts.forEach((blogPost, index) => {
    gridRows[index % rows].push(
      <Grid key={blogPost.id} item xs={12}>
        <Box mb={3} >
          <BlogCard
            src={hostBack + blogPost.mainFoto}
            title={blogPost.mainPlan}
            url={`/planes/${blogPost.mainPlan}`}
          />
        </Box>
      </Grid>
    );
  });
  return gridRows.map((element, index) => (
    <Grid key={index} item xs={xs}>
      {element}
    </Grid>
  ));
}

function Blog(props) {
  const { classes, width } = props;
  const {loading, error, data} = useQuery(FENTCH_PLANS_QUERY)
  if (loading) return <Loading min={true} topPad={true} />;
  if (error)
    return <Nothing text={`Error! ${error.message}`} top={true} min={true} />;


  return (
    <Box
      display="flex"
      justifyContent="center"
      pt={3}
      alignItems={data.getPlanes.length > 0 ? "flex-end" : "center"}
      className={classes.wrapper}
    >
      <div className={classes.blogContentWrapper}>
        <Grid container spacing={3}>
          {data.getPlanes.length > 0 ? getVerticalBlogPosts(width, data.getPlanes) : <Nothing  text="Lo sentimos aun no hemos creado ningún plan para mostrar" />}
        </Grid>
      </div>
    </Box>
  );
}

Blog.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  blogPosts: PropTypes.arrayOf(PropTypes.object),
};

export default withWidth()(withStyles(styles, { withTheme: true })(Blog));
