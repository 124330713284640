import React, { memo } from "react";
import {
  Box,
  Typography,
  isWidthUp,
  withStyles,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import Carousel from "react-elastic-carousel";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Nothing from "../../../errors/Nothing";
import Loading from "../../../errors/Loading";
import hostBack from "../../../config";

const styles = (theme) => ({
  titulo: {
    fontSize: "1.90em",
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
  },
  texto: {
    fontSize: "1.25em",
    paddingTop: "7px",
  },
  img: {
    borderRadius: 18,
  },
  principal: {
    fontSize: "2.50em",
  },
});
const GET_EVENTOS_DATA = gql`
  {
    getEventos {
      id
      foto
      message
      title

      establecimiento {
        id
        name
      }
      createdAt
      finalDate
    }
  }
`;

function Eventos(props) {
  const { classes, width, setEventos } = props;
  const eventos = [];
  const { data, loading, error } = useQuery(GET_EVENTOS_DATA);
  const settings = {
    pagination: true,
    enableAutoPlay: true,
    autoPlaySpeed: 10000,
    verticalMode: true,
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 850, itemsToShow: 2 },
  ];
  const matches = useMediaQuery("(min-width:600px)");
  if (loading) return <Loading min={true} topPad={true} />;
  if (error)
    return <Nothing text={`Error! ${error.message}`} top={true} min={true} />;

  const LONGITUD_PEDAZOS = 3; // Partir en arreglo de 3
  for (let i = 0; i < data.getEventos.length; i += LONGITUD_PEDAZOS) {
    let pedazo = data.getEventos.slice(i, i + LONGITUD_PEDAZOS);
    eventos.push(pedazo);
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={6}
      width="100%"
      flexDirection="column"
      className="lg-p-bottom"
    >
      <Typography align="center" variant="h2" className={classes.principal}>
        Eventos y sugerencias para que disfrutes cada vez mas...
      </Typography>
      <br />
      {eventos.length > 0 ? (
        <Carousel
          preventDefaultTouchmoveEvent={true}
          {...settings}
          showArrows={matches}
          showEmptySlots={false}
          breakPoints={breakPoints}
        >
          {eventos.map((evento, i) => (
            <Grid container spacing={isWidthUp("md", width) ? 10 : 5} key={i}>
              {evento.map((ev, i) => (
                <Grid
                  key={ev.id}
                  item
                  xs={12}
                  onClick={() => setEventos({ open: true, evento: ev })}
                  md={i === 1 ? 6 : 3}
                  lg={i === 1 ? 6 : 3}
                >
                  <img
                    src={`${hostBack}${ev.foto}`}
                    alt="Ciudad de demostración para la inclusión de busquedas de establecimientos en ciudades"
                    width="100%"
                    className={classes.img}
                    height="200px"
                  />
                  <Typography
                    align="center"
                    className={classes.titulo}
                    variant="h3"
                  >
                    {ev.title}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ))}
        </Carousel>
      ) : (
        <Nothing
          text={`Lo sentimos aun no se ha creado ningún evento en esta ciudad`}
          min={true}
        />
      )}
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Eventos));
