import React, { memo, useContext } from "react";
import {
  Box,
  Button,
  withStyles,
} from "@material-ui/core";
import QRCode from "react-qr-code";
import { AuthContext } from "../context/auth";

const styles = (theme) => ({
  titulo: {
    fontSize: "1.90em",
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
  },
  texto: {
    fontSize: "1.25em",
    paddingTop: "7px",
  },
  img: {
    borderRadius: 18,
  },
  principal: {
    fontSize: "2.50em",
  },
});

const RedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "56px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "0.8",
    },
  },
}))(Button);

function QRcode(props) {
  const { theme } = props;
  const { establecimiento } = useContext(AuthContext);
  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      pt={12}
      minHeight="70vh"
    >
      <QRCode
        id="QRCode"
        value={
          window.location.href.split("/codigoQR")[0] +
          `/establecimientos/${establecimiento.id}`
        }
        fgColor={theme.palette.secondary.main}
        
      />
      <Box my={5}>
        <RedButton onClick={onImageCownload}>Descargar</RedButton>
      </Box>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(memo(QRcode));
