import React, { memo } from "react";
import { useQuery, gql } from "@apollo/client";
import { Box, Typography, withStyles, Grid } from "@material-ui/core";
import Nothing from "../errors/Nothing";
import Loading from "../errors/Loading";
import hostBack from "../config";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  titulo: {
    fontSize: "1.45em",
  },
  img: {
    borderRadius: 18,
    width: "100%",
    userDrag: "none",
  },
  caja: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "12px",
  },
  principal: {
    fontSize: "2.50em",
  },
});

const GET_CATEGORIAS = gql`
  query ($categoria: String!) {
    getEstablecimientos(data: 50, categoria: $categoria) {
      id
      name
      principalImage
    }
  }
`;

function Categoria(props) {
  const { classes } = props;
  const categoria = props.match.params.categoria;
  const { data, loading, error } = useQuery(GET_CATEGORIAS, {
    variables: { categoria },
  });

  if (loading) return <Loading min={true} topPad={true} />;
  if (error)
    return <Nothing text={`Error! ${error.message}`} top={true} min={true} />;

  return (
    <Box
      display="flex"
      justifyContent="center"
      p={6}
      style={{ minHeight: "61.3vh" }}
      pt={12}
      className="lg-p-bottom "
    >
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Typography align="center" variant="h2" className={classes.principal}>
          {categoria}
        </Typography>
        <br />
        {data.getEstablecimientos.length > 0 ? (
          <Grid container>
            {data.getEstablecimientos.map((establecimiento) => (
              <Grid
                key={establecimiento.id}
                item
                lg={2}
                md={4}
                sm={6}
                xs={12}
                className={classes.caja}
              >
                <Link
                  to={`/establecimientos/${establecimiento.id}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "85%",
                  }}
                >
                  <img
                    src={hostBack + establecimiento.principalImage}
                    alt="Ciudad de demostración para la inclusión de busquedas de establecimientos en ciudades"
                    className={classes.img}
                    height="200px"
                  />
                </Link>
                <Typography
                  align="center"
                  className={classes.titulo}
                  variant="h3"
                >
                  {establecimiento.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Nothing
            fullHeight={true}
            text="No hay establecimientos registrados en esta categoria"
          />
        )}
      </div>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Categoria));
