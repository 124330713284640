import React, { memo, useState, Fragment, useEffect } from "react";
import {
  TextField,
  FormLabel,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  MenuItem,
  Box,
  Select,
  Grid,
  RadioGroup,
  InputLabel,
  FormControl,
  FormControlLabel,
  Chip,
  Input,
  withStyles,
  Radio,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import HighlightedInformation from "../..//shared/components/HighlightedInformation";

const RedRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    "&$checked": {
      color: theme.palette.secondary.main,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);
const EDIT_RESTRICCION_MUTATION = gql`
  mutation ($restriccion: RestriccionInput!) {
    updateEstablecimiento(
      establecimientoUpdate: { restriccion: $restriccion }
    ) {
      id
      restriccion {
        Mascotas {
          restringe
          data
        }
        Fumar {
          restringe
          data
        }
        Horario {
          restringe
          data
        }
        Ropa {
          restringe
          data
        }
        Edad {
          restringe
          data
        }
        Numero_de_personas {
          restringe
          data
        }
        Personalizada {
          restringe
          nombre
          data
        }
      }
    }
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
  },
  whiteInput: {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  borderMinus: {
    borderRadius: 14,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: theme.palette.common.ligthGray,
    minHeight: "56px",
    "&:hover": {
      backgroundColor: theme.palette.common.darkGray,
    },
  },
}))(Button);

function Restriccion(props) {
  const { classes, callback, restriccion } = props;

  const [errors, setErrors] = useState({});

  const [Restricciones, setRestricciones] = useState(
    restriccion
      ? Object.keys(restriccion).filter(
          (key) => key !== "__typename" && restriccion[key]
        )
      : []
  );
  const omitTypename = (key, value) =>
    key === "__typename" ? undefined : value ? value : undefined;
  JSON.parse(JSON.stringify(restriccion), omitTypename);
  const [formData, setFormData] = useState(
    restriccion ? JSON.parse(JSON.stringify(restriccion), omitTypename) : {}
  );

  useEffect(() => {
    let newValue = formData;

    const fakeRestriccion = Object.keys(newValue).filter(
      (tipo) => !Restricciones.includes(tipo)
    );
    fakeRestriccion.forEach((fake) => {
      delete newValue[fake];
    });

    Restricciones.forEach((tipo) => {
      if (tipo !== "Personalizada") {
        if (!formData[tipo]) {
          newValue = {
            ...newValue,
            [tipo]: {
              restringe: false,
              data: "",
            },
          };
        }
      } else {
        if (!formData[tipo]) {
          newValue = {
            ...newValue,
            [tipo]: { restringe: false, nombre: "", data: "" },
          };
        }
      }
    });

    setFormData(newValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Restricciones]);

  const [editRestricciones] = useMutation(EDIT_RESTRICCION_MUTATION, {
    variables: { restriccion: formData },
    onCompleted: () => callback(),
    onError: (err) =>
      setErrors({
        ...errors,
        ...err.graphQLErrors[0].extensions.errors,
      }),
  });

  const handleSubmit = () => {
    console.log(formData);
    editRestricciones();
  };

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => callback()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Box>
          <FormLabel>Edite las restricciones</FormLabel>
          <br />
          <br />
          <FormControl>
            <InputLabel id="restriccion">Restricciones</InputLabel>
            <Select
              labelId="restriccion"
              id="restriccion"
              multiple
              fullWidth
              required
              name="restriccion"
              value={Restricciones}
              onChange={(event) => setRestricciones(event.target.value)}
              input={<Input id="restriccion" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              <MenuItem value="Mascotas">Mascotas</MenuItem>
              <MenuItem value="Fumar">Fumar</MenuItem>
              <MenuItem value="Horario">Horario</MenuItem>
              <MenuItem value="Ropa">Ropa</MenuItem>
              <MenuItem value="Edad">Edad</MenuItem>
              <MenuItem value="Numero_de_personas">Numero de personas</MenuItem>
              <MenuItem value="Personalizada">Personalizada </MenuItem>
            </Select>
          </FormControl>

          {Object.keys(formData).map((restric) => {
            if (restric !== "Personalizada") {
              return (
                <Grid item xs={12} key={restric}>
                  <br />

                  <FormControl>
                    <FormLabel component="legend">
                      Indique si su establecimiento aplica alguna restricción
                      sobre: <strong>{restric}</strong>
                    </FormLabel>
                    <RadioGroup
                      value={formData[restric].restringe}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          [restric]: {
                            ...formData[restric],
                            restringe: !formData[restric].restringe,
                          },
                        })
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<RedRadio />}
                        label="Si"
                      />
                      <FormControlLabel
                        value={false}
                        control={<RedRadio />}
                        label="No"
                      />
                    </RadioGroup>
                    {formData[restric].restringe && (
                      <TextField
                        name={restric}
                        variant="outlined"
                        required
                        fullWidth
                        value={formData[restric].data}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            [restric]: {
                              ...formData[restric],
                              data: event.target.value,
                            },
                          })
                        }
                        id={restric}
                        label={"Describa la restricción"}
                      />
                    )}
                  </FormControl>
                </Grid>
              );
            } else {
              return (
                <Fragment key={restric}>
                  <Grid item xs={12}>
                    <br />
                    <TextField
                      name={restric}
                      variant="outlined"
                      required
                      fullWidth
                      value={formData[restric].nombre}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          [restric]: {
                            ...formData[restric],
                            nombre: event.target.value,
                          },
                        })
                      }
                      id={restric}
                      label={"Nombre de su restricción personalizada"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <br />
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Indique si su establecimiento aplica alguna restricción
                        sobre:{" "}
                        <strong>
                          {formData[restric].nombre !== ""
                            ? formData[restric].nombre
                            : "Restricción personalizada innombrada"}
                        </strong>
                      </FormLabel>
                      <RadioGroup
                        value={formData[restric].restringe}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            [restric]: {
                              ...formData[restric],
                              restringe: !formData[restric].restringe,
                            },
                          })
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<RedRadio />}
                          label="Si"
                        />
                        <FormControlLabel
                          value={false}
                          control={<RedRadio />}
                          label="No"
                        />
                      </RadioGroup>
                      {formData[restric].restringe && (
                        <TextField
                          name={restric}
                          variant="outlined"
                          required
                          fullWidth
                          value={formData[restric].data}
                          onChange={(event) =>
                            setFormData({
                              ...formData,
                              [restric]: {
                                ...formData[restric],
                                data: event.target.value,
                              },
                            })
                          }
                          id={restric}
                          label={"Describa la restricción"}
                        />
                      )}
                    </FormControl>
                  </Grid>
                </Fragment>
              );
            }
          })}
          {Object.keys(errors).length !== 0 && (
            <Fragment>
              <br />
              <br />
              <HighlightedInformation>
                {Object.values(errors).map((err, i, array) => {
                  return (
                    <Fragment key={i}>
                      {`*${err}`} <br /> {array.length - 1 !== i ? <br /> : ""}
                    </Fragment>
                  );
                })}
              </HighlightedInformation>
            </Fragment>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <ColorButton
          className={classes.borderMinus}
          onClick={() => callback()}
          color="default"
        >
          Cancelar
        </ColorButton>
        <ColorButton
          className={classes.borderMinus}
          onClick={handleSubmit}
          color="default"
          autoFocus
        >
          Cambiar
        </ColorButton>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Restriccion));
