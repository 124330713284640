import React, { Fragment, Suspense } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import { AuthProvider } from "./context/auth";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import "./style.css";
import LoggedOutComponent from "./logged_out/components/Main";
import Register from "./establecimiento/Register";
import Login from "./establecimiento/Login";
import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
function App() {
  dayjs.locale("es");
  dayjs.extend(relativeTime);
  return (
    <AuthProvider>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            <Switch>
              <Route path="/establecimiento/login" component={Login} />
              <Route path="/establecimiento/register" component={Register} />
              <Route path="/" component={LoggedOutComponent} />
            </Switch>
          </Suspense>
        </MuiThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
