import React, { memo } from "react";
import { useQuery, gql } from "@apollo/client";
import { Box, Typography, withStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Carousel from "react-elastic-carousel";
import hostBack from "../config";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Loading from "../errors/Loading";
import Nothing from "../errors/Nothing";
const styles = (theme) => ({
  titulo: {
    fontSize: "1.45em",
  },
  img: {
    borderRadius: 18,
    width: "100%",
    userDrag: "none",
  },
  caja: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  principal: {
    fontSize: "3em",
  },
  subPrincipal: {
    fontSize: "2.50em",
    color: theme.palette.secondary.main,
  },
  gray: {
    backgroundColor: theme.palette.common.ligthGray,
  },
});

const GET_PLANES_ESTABLECIMIENTOS = gql`
  query ($plan: String!) {
    getOnlyPlan(plan: $plan) {
      id
      mainPlan
      mainFoto

      subPlans {
        nombre
        establecimientos {
          id
          ciudad
          principalImage
          name
        }
      }
    }
  }
`;

function Planes(props) {
  const { classes } = props;
  const matches = useMediaQuery("(min-width:600px)");

  const { data, loading, error } = useQuery(GET_PLANES_ESTABLECIMIENTOS, {
    variables: { plan: props.match.params.plan },
  });

  const settings = {
    pagination: true,
    enableAutoPlay: true,
    autoPlaySpeed: 3500,
    showEmptySlots: true,
  };
  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 3 },
    { width: 850, itemsToShow: 5 },
  ];
  if (loading) return <Loading min={true} topPad={true} />;
  if (error)
    return <Nothing text={`Error! ${error.message}`} top={true} min={true} />;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      style={{ minHeight: "60vh" }}
      pt={12}
      className="lg-p-bottom"
    >
      <div style={{ width: "100%" }}>
        <Typography align="center" variant="h2" className={classes.principal}>
          <strong>{data.getOnlyPlan.mainPlan}</strong>
        </Typography>
        <br />
        {data.getOnlyPlan.subPlans.map((plan, i) => (
          <Box
            p={6}
            className={i % 2 !== 0 ? classes.gray : ""}
            mb={3}
            key={plan.nombre}
          >
            <Typography className={classes.subPrincipal} variant="h3">
              <strong>{plan.nombre}</strong>
            </Typography>
            <br />
            {plan.establecimientos.length > 0 ? (
              <Carousel
                showArrows={matches}
                {...settings}
                breakPoints={breakPoints}
              >
                {plan.establecimientos.map((establecimiento) => (
                  <Box key={establecimiento.titulo} className={classes.caja}>
                    <Link to={`/establecimientos/${establecimiento.id}`}>
                      <img
                        src={hostBack + establecimiento.principalImage}
                        alt="Ciudad de demostración para la inclusión de busquedas de establecimientos en ciudades"
                        className={
                          matches
                            ? classes.img
                            : classNames(classes.img, classes.heightFixed)
                        }
                        height="200px"
                      />
                    </Link>
                    <Typography
                      align="center"
                      className={classes.titulo}
                      variant="h3"
                    >
                      <strong>{establecimiento.name}</strong>
                    </Typography>
                  </Box>
                ))}
              </Carousel>
            ) : (
              <Nothing text="No hay establecimientos registrados en este plan" />
            )}
          </Box>
        ))}
      </div>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Planes));
