import React, { useContext, Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  withStyles,
  CardActions,
  Button,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ColoredButton from "../../../shared/components/ColoredButton";
import { AuthContext } from "../../../context/auth";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import dayjs from "dayjs";

import ReadMore from "@sstraatemans/react-readmore";
const RedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "56px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "0.8",
    },
  },
}))(Button);
const GreenButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1f991f",
    minHeight: "56px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#1f991f",
      opacity: "0.8",
    },
  },
}))(Button);
const styles = (theme) => ({
  termsConditionsListitem: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  dialogActions: {
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.common.darkGray,
  },
  backIcon: {
    marginRight: theme.spacing(1),
  },
  card: {
    textDecoration: "none",
    backgroundColor: theme.palette.common.ligthGray,
    "&link": {
      textDecoration: "none",
    },
  },
  headBody: {
    backgroundColor: theme.palette.common.darkGray,
    color: "#fff",
  },
  body: {
    backgroundColor: theme.palette.common.darkGray,
  },
  img: {
    width: "30%",
    borderRadius: "8px",
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  content: {
    textAlign: "justify",
  },
  marginTop: {
    marginTop: "12px",
  },
});

const CONFIRM_RESERVA_MUTATION = gql`
  mutation ($idRes: String!, $type: Boolean!) {
    confirmarPeticion(idRes: $idRes, type: $type) {
      id
      to
      message
      title
      reserva {
        user {
          id
          username
          telefono
        }
        establecimiento {
          id
          name
        }
        createdAt
        status
      }
      from
      createdAt
      global {
        isEstablecimiento
      }
    }
  }
`;
const UPDATE_USER_COUNT = gql`
  mutation {
    updateCountUser
  }
`;
const UPDATE_ESTABLECIMIENTO_COUNT = gql`
  mutation {
    updateCountEst
  }
`;
function Notifications(props) {
  const { classes, onClose, theme } = props;

  const {
    notifications,
    loading,
    setError,
    user,
    establecimiento,
    count,
    error,
    setCount,
    updateNotifications,
  } = useContext(AuthContext);
  const [setNotifications] = useMutation(UPDATE_USER_COUNT, {
    onCompleted: (data) => setCount(data.updateCountUser),
  });
  const [setNotificationsEst] = useMutation(UPDATE_ESTABLECIMIENTO_COUNT, {
    onCompleted: (data) => setCount(data.updateCountEst),
  });
  const [confirmarReserva, { loading: mutateLoading }] = useMutation(
    CONFIRM_RESERVA_MUTATION,
    {
      onCompleted: (data) => updateNotifications(data.confirmarPeticion),
      onError: (err) => setError(err),
    }
  );

  if (count !== notifications.length) {
    if (user) {
      setNotifications();
    } else {
      setNotificationsEst();
    }
  }

  return (
    <Dialog open scroll="paper" onClose={onClose}>
      <DialogTitle className={classes.headBody}>Notificaciones</DialogTitle>
      <DialogContent className={classes.body}>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <ErrorIcon />

            <Typography>{error.message}</Typography>
          </Box>
        ) : notifications.length > 0 ? (
          notifications.map((result, i) => (
            <Box className={i === 0 ? "" : classes.marginTop} key={i}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.header}
                  title={result.title}
                  subheaderTypographyProps={{
                    style: { color: "#000" },
                  }}
                  subheader={dayjs(result.createdAt).fromNow()}
                />
                <CardContent className={classes.content}>
                  {result.reserva && user ? (
                    <Typography paragraph>
                      El estado de su petición en{" "}
                      <strong>{result.reserva.establecimiento.name}</strong> es:{" "}
                      <strong>{result.reserva.status}</strong>
                    </Typography>
                  ) : result.reserva && establecimiento ? (
                    <Fragment>
                      <Typography align="justify" paragraph>
                        {result.message}
                      </Typography>
                      <Typography style={{ margin: 0 }} paragraph>
                        estado: {result.reserva.status}
                      </Typography>

                      <Typography paragraph>
                        Numero de contacto:{" "}
                        <strong>{result.reserva.user.telefono}</strong>
                      </Typography>
                    </Fragment>
                  ) : (
                    <ReadMore
                      maxLines={2}
                      readMoreLabel="Leer mas"
                      readLessLabel="Leer menos"
                    >
                      {result.message}
                    </ReadMore>
                  )}
                </CardContent>
                {result.reserva &&
                  result.reserva.status === "PENDIENTE" &&
                  establecimiento && (
                    <CardActions>
                      <RedButton
                        disabled={mutateLoading}
                        onClick={() =>
                          confirmarReserva({
                            variables: { idRes: result.id, type: false },
                          })
                        }
                      >
                        Rechazar
                      </RedButton>
                      <GreenButton
                        disabled={mutateLoading}
                        onClick={() =>
                          confirmarReserva({
                            variables: { idRes: result.id, type: true },
                          })
                        }
                      >
                        Aceptar
                      </GreenButton>
                    </CardActions>
                  )}
              </Card>
            </Box>
          ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <ErrorIcon />

            <Typography>No hay notificaciones</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ColoredButton
          onClick={onClose}
          variant="contained"
          color={theme.palette.common.black}
        >
          <ArrowBackIcon className={classes.backIcon} />
          Salir
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles, { withTheme: true })(Notifications);
