import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  withStyles,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ColoredButton from "../../../shared/components/ColoredButton";

const styles = (theme) => ({
  dialogActions: {
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialog: {
    zIndex: 1400,
  },
  backIcon: {
    marginRight: theme.spacing(1),
  },
});

function PoliticasCondiciones(props) {
  const { classes, onClose, open, theme } = props;
  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={onClose}
      className={classes.dialog}
    >
      <DialogTitle> POLÍTICA DE PRIVACIDAD </DialogTitle>
      <DialogContent>
        <Typography variant="h5" color="primary" paragraph>
          DEFINICIONES
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato personal:</strong> Cualquier información concerniente o vinculada a
          personas naturales o jurídicas determinadas o determinables.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Titular del dato personal:</strong> Persona natural cuyos datos son objeto de
          tratamiento. En el contexto de la presente política de tratamiento de
          datos personales los titulares podrán ser: (i) usuarios de la
          plataforma; (ii) clientes; (iii) proveedores (iv) todas aquellas
          personas no vinculadas a PARCHATE S.A.S. de quien se haga tratamiento
          de los datos personales.{" "}
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Base de datos personales:</strong> Conjunto organizado de datos personales que
          son objeto de tratamiento por una persona natural o jurídica.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato sensible:</strong> Es aquel dato personal que afecta la intimidad del
          Titular y cuyo uso incorrecto podría generar discriminación. Son
          considerados datos sensibles entre otros, los datos de salud, los
          datos de orientación sexual, origen racial y étnico, opiniones
          políticas, convicciones religiosas, filosóficas o morales.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato privado:</strong> Es aquel dato personal que por su carácter íntimo o
          reservado es relevante para el Titular.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato Semiprivado:</strong> Es aquel dato personal conocido y de interés tanto
          para el titular como para un determinado sector de personas o para la
          sociedad en general, por lo que no es de carácter íntimo, reservado o
          público.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Dato público:</strong> Es aquel dato personal calificado como tal según la
          Constitución y la ley, y que no se ha clasificado como dato personal
          privado o semiprivado.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Habeas data:</strong> Es el derecho que tiene el Titular de los datos
          personales de exigir de las administradoras de estos el acceso,
          inclusión, exclusión, corrección, adición, actualización y
          rectificación de los datos, así como la limitación en su divulgación,
          publicación o cesión.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Autorización:</strong> Consentimiento previo, expreso e informado del Titular
          para llevar a cabo el tratamiento de datos personales.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Aviso de privacidad:</strong> Comunicación verbal o escrita dirigida a los
          Titulares de los datos personales que están siendo tratados por la
          empresa, en la cual se le informa acerca de la existencia de las
          políticas de tratamiento de datos personales que le serán aplicadas,
          la forma de acceder a la mismas, y las finalidades para las cuales
          serán usados sus datos personales.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Responsable del tratamiento:</strong> Persona natural o jurídica de carácter
          público o privado que por sí misma o en asocio con otro u otros decide
          sobre el tratamiento de datos personales. En este caso, será PARCHATE
          S.A.S. el responsable del tratamiento.
        </Typography>
        <Typography
          align="justify"
          paragraph
          className={classes.termsConditionsListitem}
        >
          <strong>Cookies:</strong> Las cookies son archivos que recogen información a través de
          una página web sobre los hábitos de navegación de un usuario o de su
          equipo y eventualmente podrían conformar una base de datos de acuerdo
          a la definición legal de la Ley 1581 de 2012 al recolectar datos
          personales conforme a las siguientes características: (i) están
          referidos a aspectos exclusivos y propios de una persona natural, ii)
          permiten identificar a la persona, en mayor o menor medida, gracias a
          la visión de conjunto que se logre con el mismo y con otros datos;
          iii) su propiedad reside exclusivamente en el titular del mismo,
          situación que no se altera por su obtención por parte de un tercero de
          manera lícita o ilícita, y iv) su tratamiento está sometido a reglas
          especiales (principios) en lo relativo a su captación, administración
          y divulgación; caso en el cual, el responsable deberá ceñirse por las
          normas sobre protección de datos vigentes en Colombia, en especial la
          aplicación de los principios rectores para la administración de datos
          de legalidad, finalidad, libertad, veracidad o calidad, transparencia,
          acceso y circulación restringida, seguridad y confidencialidad
          consagrados en el artículo 4 de la Ley 1581 de 2012.
        </Typography>
        <Typography variant="h5" color="primary">
          HABEAS DATA
        </Typography>
        <Typography paragraph align="justify">
          Para dar cumplimiento con la ley 1266 de 2008 y la ley 1581 de 2012,
          Parchate SAS almacenará de forma virtual la información que el usuario
          y el cliente suministre por medio de los formularios pedidos. La
          información proveída por los clientes será de carácter pública con
          fines publicitarios y de promoción comercial en la aplicación;
          información que puedes conocer, actualizar y rectificar en cualquier
          momento. Por otro lado, la información privada como cámara de
          comercio, RUT, datos de contacto entre otros, será tratada de manera
          privada y Parchate SAS no estará en la capacidad de vender o poner a
          disposición de un tercero los datos brindados. Esta información
          privada será proporcionada una única vez y será almacenada de forma
          física en la oficina de Parchate SAS con domicilio en la ciudad de
          Bogotá. Puedes conocer, actualizar y rectificar esta información en
          cualquier momento. La información que registren los usuarios será de
          carácter privado, de igual forma puedes conocer, actualizar y
          rectificar esta información en cualquier momento, se almacenará de
          forma virtual por nosotros y no estaremos en la capacidad de vender a
          un tercero estos datos. La información será vista por los clientes en
          los informes mensuales, la información como edad, país y ciudad será
          utilizada de forma anónima y no se conocerán los datos personales
          tales como nombre, apellido, e-mail y género. Entendemos que has leído
          los términos y condiciones y cualesquiera otros términos que te
          hayamos ofrecido, de esta forma y luego de aceptar estos términos,
          autorizas y apruebas a Parchate SAS para dar uso y tratar tus datos de
          acuerdo con lo leído en este documento.{" "}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ColoredButton
          onClick={onClose}
          variant="contained"
          color={theme.palette.common.black}
        >
          <ArrowBackIcon className={classes.backIcon} />
          atrás
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}

PoliticasCondiciones.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PoliticasCondiciones);
