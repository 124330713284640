import React, { memo, useState, Fragment, useContext } from "react";
import {
  Box,
  Grid,
  Button,
  withStyles,
  TableHead,
  DialogActions,
} from "@material-ui/core";
import {
  FacebookShareButton,
  FacebookIcon as FaceIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { DropzoneDialog } from "material-ui-dropzone";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import ShareIcon from "@material-ui/icons/Share";
import {
  IconButton,
  useMediaQuery,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import RoomIcon from "@material-ui/icons/Room";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { AuthContext } from "../context/auth";
import Slide from "@material-ui/core/Slide";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneIcon from "@material-ui/icons/Phone";
import ClearIcon from "@material-ui/icons/Clear";
import Descripcion from "./editor/Descripcion";
import Ubicacion from "./editor/Ubicacion";
import UploadMenu from "./editor/UploadMenu";
import Restriccion from "./editor/Restriccion";
import HomeIcon from "@material-ui/icons/Home";
import Producto from "./editor/Producto";
import Principal from "./Principal";
import MenuServicios from "./MenuServicios";
import AddFavorite from "./AddFavorite";
import Nothing from "../errors/Nothing";
import Loading from "../errors/Loading";
import Reserva from "./editor/Reserva";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  titulo: {
    fontSize: "1.45em",
  },

  radiusBottom: {
    borderRadius: 32,
  },
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
  },
  dialogAction: {
    paddingTop: 0,
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
  },
  caja: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "12px",
  },
  principal: {
    fontSize: "2.50em",
  },
  icon: {
    fontSize: "44px",
    textAlign: "center",
    cursor: "pointer",
  },
  whiteInput: {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  borderMinus: {
    borderRadius: 14,
  },
  socialIcon: {
    color: theme.palette.common.white,

    backgroundColor: "#000",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
});
const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: theme.palette.common.ligthGray,
    minHeight: "56px",
    "&:hover": {
      backgroundColor: theme.palette.common.darkGray,
    },
  },
}))(Button);

const ADD_IMAGES_MUTATION = gql`
  mutation ($fotos_y_videos: [Upload]!) {
    addImageToEstablecimiento(fotos_y_videos: $fotos_y_videos) {
      id
      fotos_y_videos
    }
  }
`;
const CHANGE_PRINCIPAL_PHOTO = gql`
  mutation ($mainFoto: Upload!) {
    addImageToEstablecimiento(mainFoto: $mainFoto) {
      id
      principalImage
    }
  }
`;
const GET_ESTABLECIMIENTO_QUERY = gql`
  query ($id: String!) {
    getEstablecimiento(id: $id) {
      id
      name
      contacto {
        Facebook
        Instagram
        Celular
        Whatsapp
      }
      email
      principalImage
      fotos_y_videos
      ciudad
      descripcion
      categoria
      alimentos {
        foto
        precio
        descripcion
        nombre
      }
      bebidas {
        foto
        precio
        descripcion
        nombre
      }
      complementos {
        foto
        precio
        descripcion
        nombre
      }
      servicios {
        foto
        precio
        descripcion
        nombre
      }
      ubicacion {
        Google_maps
        Waze
      }
      restriccion {
        Mascotas {
          restringe
          data
        }
        Fumar {
          restringe
          data
        }
        Horario {
          restringe
          data
        }
        Ropa {
          restringe
          data
        }
        Edad {
          restringe
          data
        }
        Numero_de_personas {
          restringe
          data
        }
        Personalizada {
          restringe
          nombre
          data
        }
      }
      reservas {
        days
        avaible
        hours
      }
    }
  }
`;
function seeWhatStart(establecimiento, edited) {
  if (establecimiento.alimentos.length > 0) {
    return "alimentos";
  }
  if (establecimiento.bebidas.length > 0) {
    return "bebidas";
  }
  if (establecimiento.servicios.length > 0) {
    return "servicios";
  }
  if (establecimiento.complementos.length > 0) {
    return "complementos";
  }
  if (edited) {
    return "alimentos";
  }
  return "";
}
function Establecimiento(props) {
  const { establecimiento: establecimientoContext, user } =
    useContext(AuthContext);

  const { classes, edited } = props;

  if (edited && !establecimientoContext) props.history.push("/");
  const [open, setOpen] = useState({ open: false, type: "ubicacion" });
  const [restricted, setRestricted] = useState(false);
  const [principalEdit, setPrincipalEdit] = useState(false);
  const [photosEditor, setPhotosEditor] = useState(false);
  const [reserva, setReserva] = useState(false);

  const [descripcionEdit, setDescripcionEdit] = useState(false);
  const [detalle, setDetalle] = useState({ open: false, producto: {} });

  const { data, loading, error } = useQuery(GET_ESTABLECIMIENTO_QUERY, {
    variables: {
      id: edited ? establecimientoContext.id : props.match.params.id,
    },
    onCompleted: () =>
      setPrincipalScreen({
        ...principalScreen,
        type: seeWhatStart(data.getEstablecimiento, edited),
      }),
  });

  const [principalScreen, setPrincipalScreen] = useState({
    principal: "principal",
    type: "",
  });
  const [addingPhotos, setAddingPhotos] = useState(false);
  const [restriccionEdit, setRestriccionEdit] = useState(false);
  const [ubicacionEdit, setUbicacionEdit] = useState({
    open: false,
    type: "ubicacion",
  });

  const [share, setShare] = useState(false);
  const openUbicacion = (type) => {
    setOpen({ type, open: false });
    setUbicacionEdit({ open: true, type });
  };
  const closeUbicacion = (type) => {
    setOpen({ open: true, type });
    setUbicacionEdit({ type, open: false });
  };
  const openRestriccion = () => {
    setRestricted(false);
    setRestriccionEdit(true);
  };
  const closeRestriccion = () => {
    setRestriccionEdit(false);
    setRestricted(true);
  };
  const handleClose = () => {
    setOpen({ ...open, open: false });
  };
  const handlePrincipalChange = () => {
    switch (principalScreen.principal) {
      case "principal":
        setPrincipalScreen({ ...principalScreen, principal: "menu" });
        break;
      default:
        setPrincipalScreen({ ...principalScreen, principal: "principal" });
    }
  };
  const matches = useMediaQuery("(max-width:600px)");

  const [uploadPrincipal] = useMutation(CHANGE_PRINCIPAL_PHOTO, {
    onCompleted: () => setPrincipalEdit(false),
    onError: (err) => alert(err),
  });
  const [uploadImages] = useMutation(ADD_IMAGES_MUTATION, {
    onCompleted: () => setPhotosEditor(false),
    onError: (err) => alert(err),
  });

  const selectIcon = (media) => {
    switch (media) {
      case "Facebook":
        return <FacebookIcon className={classes.icon} />;
      case "Instagram":
        return <InstagramIcon className={classes.icon} />;
      case "Whatsapp":
        return <WhatsAppIcon className={classes.icon} />;
      case "Celular":
        return <PhoneIcon className={classes.icon} />;
      case "Waze":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classes.icon}
            aria-hidden="true"
            focusable="false"
            width="1em"
            height="1em"
            style={{ transform: "rotate(360deg)" }}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M20.54 6.63c.69.94 1.15 2.04 1.35 3.19c.21 1.25.11 2.52-.31 3.72a7.349 7.349 0 0 1-2 3.06a9.1 9.1 0 0 1-2.26 1.58c.41 1.07-.13 2.27-1.2 2.68c-.24.09-.49.14-.74.14a2.08 2.08 0 0 1-2.07-2h-3.07c-.11 1.14-1.13 2-2.27 1.87c-1.06-.1-1.86-.98-1.88-2.04c.01-.19.04-.39.1-.57a8.37 8.37 0 0 1-4-2.85c-.33-.44-.23-1.07.23-1.41c.18-.14.4-.22.63-.22c.72 0 1-.25 1.17-.63c.24-.72.38-1.47.39-2.23c.03-.53.09-1.05.17-1.57A7.307 7.307 0 0 1 7.5 5c1.66-1.3 3.69-2 5.79-2c1.43 0 2.84.35 4.11 1a8.67 8.67 0 0 1 3.14 2.63m-3.82 10.68c1.78-.81 3.18-2.27 3.87-4.1c1.62-4.94-2.59-9.16-7.3-9.16c-.35 0-.71.02-1.06.07C9.36 4.5 6.4 6.5 5.81 9.5c-.38 2 .19 5.29-2.76 5.29C4 16 5.32 16.93 6.81 17.37c.85-.76 2.16-.68 2.93.18c.11.12.2.25.26.39h3.55c.52-1.02 1.78-1.44 2.8-.9c.15.08.25.17.37.27m-5.75-7c-.58.03-1.09-.41-1.12-1c-.03-.58.42-1.08 1-1.12c.58-.03 1.09.42 1.12 1.06a.999.999 0 0 1-.97 1.04l-.03.02m4.69 0c-.58.03-1.09-.41-1.12-1c-.04-.58.42-1.08 1-1.12c.58-.03 1.09.42 1.12 1.06c.02.55-.41 1.02-1 1.04v.02m-5.95 1.76c-.06-.28.13-.57.41-.62c.28-.05.56.13.62.41a2.501 2.501 0 0 0 2.58 1.74c1.14.06 2.18-.64 2.57-1.72c.14-.26.46-.38.71-.23c.18.1.29.27.29.47c-.19.71-.63 1.33-1.23 1.76c-.69.48-1.5.75-2.34.76h-.11c-1.63.07-3.1-1-3.53-2.58l.03.01z"
              fill="#fff"
            />
          </svg>
        );
      case "Google_maps":
        return <RoomIcon className={classes.icon} />;
      default:
        return null;
    }
  };
  const handlePrincipalUpload = (files) => {
    uploadPrincipal({ variables: { mainFoto: files[0] } });
  };
  const handleFotosUpload = (files) => {
    uploadImages({ variables: { fotos_y_videos: files } });
  };

  if (loading) return <Loading min={true} topPad={true} />;
  if (error)
    return <Nothing text={`Error! ${error.message}`} top={true} min={true} />;
  const establecimiento = data.getEstablecimiento;

  return (
    <Box
      display="flex"
      p={6}
      style={{ minHeight: "61.3vh" }}
      flexDirection="column"
      pt={12}
      className="lg-p-bottom "
    >
      <br />

      <Grid container spacing={2}>
        {principalScreen.principal === "principal" ? (
          <Principal
            establecimiento={establecimiento}
            setPrincipalEdit={setPrincipalEdit}
            matches={matches}
            edited={edited}
            setDescripcionEdit={setDescripcionEdit}
            callback={() => setPhotosEditor(true)}
          />
        ) : (
          <MenuServicios
            menu={establecimiento}
            openDetail={setDetalle}
            matched={matches}
            edited={edited}
            principalScreen={principalScreen}
            changeType={setPrincipalScreen}
            callback={() => setAddingPhotos(true)}
          />
        )}
        <Grid item xs={12} md={2}>
          <Box mb={1} display="flex" justifyContent="center">
            {user && (
              <AddFavorite
                user={user}
                classes={classes}
                estId={establecimiento.id}
              />
            )}
            <Box>
              <ShareIcon
                onClick={() => setShare(true)}
                className={classes.icon}
              />
            </Box>
          </Box>
          <Box>
            <ColorButton
              onClick={handlePrincipalChange}
              className={classes.radiusBottom}
              fullWidth
            >
              {principalScreen.principal === "principal" ? (
                <ListAltIcon className={classes.icon} />
              ) : (
                <HomeIcon className={classes.icon} />
              )}
            </ColorButton>
          </Box>
          <br />
          <Box>
            <ColorButton
              className={classes.radiusBottom}
              onClick={() => setOpen({ open: true, type: "ubicacion" })}
              fullWidth
            >
              <RoomIcon className={classes.icon} />
            </ColorButton>
          </Box>
          <br />
          <Box>
            <ColorButton
              className={classes.radiusBottom}
              onClick={() => setOpen({ open: true, type: "contacto" })}
              fullWidth
            >
              <p>Contacto</p>
            </ColorButton>
          </Box>
          <br />
          <Box>
            <ColorButton
              className={classes.radiusBottom}
              onClick={() => setRestricted(true)}
              fullWidth
            >
              <p>Restricciones</p>
            </ColorButton>
          </Box>
          {(user || edited) && (
            <Fragment>
              <br />
              <Box>
                <ColorButton
                  className={classes.radiusBottom}
                  onClick={() => {
                    setReserva(true);
                  }}
                  fullWidth
                >
                  <p>Reservar</p>
                </ColorButton>
              </Box>
            </Fragment>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={open.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.dialog}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {establecimiento[open.type] ? (
              Object.entries(establecimiento[open.type]).map(
                (contacto, index) => {
                  if (contacto[1] && contacto[0] !== "__typename") {
                    return (
                      <Box
                        key={index}
                        mr={
                          establecimiento[open.type] &&
                          index !==
                            Object.keys(establecimiento[open.type]).length - 1
                            ? 2
                            : 0
                        }
                      >
                        <IconButton
                          target="_blank"
                          className={classes.socialIcon}
                          href={
                            contacto[0] === "Whatsapp"
                              ? `https://wa.me/${contacto[1]}`
                              : contacto[0] === "Celular"
                              ? `tel:${contacto[1]}`
                              : contacto[1]
                          }
                        >
                          {selectIcon(contacto[0])}
                        </IconButton>
                      </Box>
                    );
                  } else {
                    return null;
                  }
                }
              )
            ) : (
              <Nothing text={`No hay ningún medio de contacto`} />
            )}
          </Box>
        </DialogContent>
        {edited && (
          <DialogActions className={classes.dialogAction}>
            <ColorButton
              className={classes.borderMinus}
              onClick={() => openUbicacion(open.type)}
              color="default"
              autoFocus
            >
              Editar
            </ColorButton>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={share}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShare(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.dialog}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={2}>
              <FacebookShareButton url={window.location.href}>
                <FaceIcon size={40} round={true} />
              </FacebookShareButton>
            </Box>
            <Box mr={2}>
              <TelegramShareButton url={window.location.href}>
                <TelegramIcon size={40} round={true} />
              </TelegramShareButton>
            </Box>
            <Box mr={2}>
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon size={40} round={true} />
              </TwitterShareButton>
            </Box>
            <Box mr={2}>
              <WhatsappShareButton url={window.location.href}>
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={restricted}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setRestricted(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.dialog}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Permite</TableCell>
                    <TableCell align="center">SI</TableCell>
                    <TableCell align="center">NO</TableCell>
                    <TableCell align="center">Detalle de restricción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {establecimiento.restriccion &&
                    Object.entries(establecimiento.restriccion).map(
                      (restric, i) => {
                        if (
                          restric[1] !== null &&
                          restric[0] !== "__typename"
                        ) {
                          if (restric[0] !== "Personalizada") {
                            return (
                              <TableRow key={i}>
                                <TableCell>{restric[0]}</TableCell>
                                <TableCell align="center">
                                  {restric[1].restringe ? <ClearIcon /> : null}
                                </TableCell>
                                <TableCell align="center">
                                  {!restric[1].restringe ? <ClearIcon /> : null}
                                </TableCell>
                                <TableCell align="left">
                                  {restric[1].data}
                                </TableCell>
                              </TableRow>
                            );
                          } else {
                            return (
                              <TableRow key={i}>
                                <TableCell>{restric[1].nombre}</TableCell>
                                <TableCell align="center">
                                  {restric[1].restringe ? <ClearIcon /> : null}
                                </TableCell>
                                <TableCell align="center">
                                  {!restric[1].restringe ? <ClearIcon /> : null}
                                </TableCell>
                                <TableCell align="left">
                                  {restric[1].data}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        } else {
                          return null;
                        }
                      }
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>

        {edited && (
          <DialogActions className={classes.dialogAction}>
            <ColorButton
              className={classes.borderMinus}
              onClick={openRestriccion}
              color="default"
              autoFocus
            >
              Editar
            </ColorButton>
          </DialogActions>
        )}
      </Dialog>
      {reserva && (
        <Reserva
          onClose={() => setReserva(false)}
          blockDays={establecimiento.reservas.days}
          estID={establecimiento.id}
          edited={edited}
          reserva={establecimiento.reservas}
        />
      )}
      {detalle.open && (
        <Producto
          detalle={detalle}
          tipo={principalScreen.type}
          setDetalle={setDetalle}
          edited={edited}
        />
      )}
      {edited && (
        <Fragment>
          <DropzoneDialog
            acceptedFiles={["image/*"]}
            cancelButtonText={"cancelar"}
            submitButtonText={"cambiar"}
            filesLimit={1}
            dropzoneText={"Arrastre su imagen o de click"}
            dialogTitle={"Cambiar imagen principal"}
            maxFileSize={5000000}
            open={principalEdit}
            onClose={() => setPrincipalEdit(false)}
            onSave={(files) => {
              handlePrincipalUpload(files);
            }}
            showPreviews={true}
            previewText="Imagen:"
            showFileNamesInPreview={false}
          />
          <DropzoneDialog
            acceptedFiles={["image/*"]}
            cancelButtonText={"cancelar"}
            submitButtonText={"añadir"}
            filesLimit={5}
            dropzoneText={"Arrastre su imagen o de click"}
            dialogTitle={"Agrege imagenes a su establecimiento"}
            maxFileSize={5000000}
            open={photosEditor}
            onClose={() => setPhotosEditor(false)}
            onSave={(files) => {
              handleFotosUpload(files);
            }}
            showPreviews={true}
            previewText="Imagen:"
            showFileNamesInPreview={false}
          />
          {descripcionEdit && (
            <Descripcion
              descripcion={establecimiento.descripcion}
              callback={setDescripcionEdit}
            />
          )}

          {ubicacionEdit.open && (
            <Ubicacion
              type={ubicacionEdit.type}
              ubicacion={establecimiento[ubicacionEdit.type]}
              callback={() => closeUbicacion(ubicacionEdit.type)}
            />
          )}
          {restriccionEdit && (
            <Restriccion
              callback={closeRestriccion}
              restriccion={establecimiento.restriccion}
            />
          )}
          {addingPhotos && (
            <UploadMenu
              type={principalScreen.type}
              callback={() => setAddingPhotos(false)}
            />
          )}
        </Fragment>
      )}
    </Box>
  );
}
export default withStyles(styles, { withTheme: true })(memo(Establecimiento));
