import React, {
  useState,
  useCallback,
  useRef,
  Fragment,
  useContext,
} from "react";
import PropTypes from "prop-types";
import {
  FormHelperText,
  TextField,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import FormDialog from "../../../shared/components/FormDialog";
import HighlightedInformation from "../../../shared/components/HighlightedInformation";
import ButtonCircularProgress from "../../../shared/components/ButtonCircularProgress";
import VisibilityPasswordTextField from "../../../shared/components/VisibilityPasswordTextField";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../../context/auth";
const Ciudades = [
  "Bogotá",
  "Medellín",
  "Cali",
  "Barranquilla",
  "Cartagena",
  "Bucaramanga",
  "Santa Marta",
  "Manizales",
  "Pereira",
  "Cúcuta",
  "Ibagué",
  "Villavicencio",
  "Pasto",
  "Valledupar",
  "Armenia",
  "Montería",
  "Neiva",
  "Popoyán",
  "Tunja",
  "Quibdó",
  "Florencia",
  "Sincelejo",
  "Buenaventura",
  "Riohacha",
  "Soacha",
  "Yopal",
  "Barrancabermeja",
  "Tuluá",
  "Yumbo",
  "Palmira",
  "Zipaquirá",
  "Girardot",
  "Bello",
  "Floridablanca",
  "Duitama",
  "Soledad",
  "Rionegro",
  "Tumaco",
  "Facatativá",
  "Girón",
  "Jamundí",
  "Piedecuesta",
  "Mocoa",
  "Buga",
  "Ipiales",
  "Envigado",
  "Letecia",
  "Apartadó",
  "Arauca",
  "San José del Guavire",
  "Cartago",
];
const REGISTER_USER_MUTATION = gql`
  mutation (
    $registerInput: RegisterInput!
  ) {
    register(
      registerInput: $registerInput
    ) {
      id
      email
      username
      token
    }
  }
`;
const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  link: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
});

function RegisterDialog(props) {
  const { setStatus, theme, onClose, openTermsDialog, status, classes, formData, handleChange, handleNumericChange } = props;
  const { login } = useContext(AuthContext);
  
  const [isErrors, setIsError] = useState(null)
  const [hasTermsOfServiceError, setHasTermsOfServiceError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [createUser, { loading: isLoading }] = useMutation(
    REGISTER_USER_MUTATION,
    {
      variables: { registerInput: formData },
      onCompleted: (data) => {
        
        login(data.register);
        onClose();
      },
      onError: (err) => setIsError({ ...err.graphQLErrors[0].extensions.errors || err })
    }
  );
  const registerTermsCheckbox = useRef();
  const registerPassword = useRef();
  const registerPasswordRepeat = useRef();
  

  const register = useCallback(() => {
    setIsError(null)
    if (!registerTermsCheckbox.current.checked) {
      setHasTermsOfServiceError(true);
      return;
    }
    if (
      registerPassword.current.value !== registerPasswordRepeat.current.value
    ) {
      setStatus("passwordsDontMatch");
      return;
    }
    setStatus(null);
    console.log(formData);
    createUser();
  }, [
    setStatus,
    createUser,
    setHasTermsOfServiceError,
    registerPassword,
    formData,
    registerPasswordRepeat,
    registerTermsCheckbox,
  ]);

  return (
    <FormDialog
      loading={isLoading}
      onClose={onClose}
      open
      headline="Registrarse"
      onFormSubmit={(e) => {
        e.preventDefault();
        register();
      }}
      hideBackdrop
      hasCloseIcon
      content={
        <Fragment>
          <TextField
            variant="outlined"
            required
            margin="normal"
            fullWidth
            value={formData.username}
            autoFocus
            id="username"
            onChange={handleChange}
            label="Nombre Completo"
            name="username"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={status === "invalidEmail"}
            label="Correo electronico"
            
            name="email"
            value={formData.email}
            type="email"
            onChange={(event) => {
              if (status === "invalidEmail") {
                handleChange(event);
                setStatus(null);
              } else {
                handleChange(event);
              }
            }}
            
          />
          <VisibilityPasswordTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={
              status === "passwordTooShort" || status === "passwordsDontMatch"
            }
            label="Contraseña"
            inputRef={registerPassword}
            name="password"
            autoComplete="off"
            onChange={(event) => {
              if (
                status === "passwordTooShort" ||
                status === "passwordsDontMatch"
              ) {
                setStatus(null);
                handleChange(event);
              } else {
                handleChange(event);
              }
            }}
            helperText={(() => {
              if (status === "passwordTooShort") {
                return "Crea una contraseña de al menos 6 caracteres.";
              }
              if (status === "passwordsDontMatch") {
                return "Tus contraseñas no coinciden.";
              }
              return null;
            })()}
            value={formData.password}
            FormHelperTextProps={{ error: true }}
            isVisible={isPasswordVisible}
            onVisibilityChange={setIsPasswordVisible}
          />
          <VisibilityPasswordTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={
              status === "passwordTooShort" || status === "passwordsDontMatch"
            }
            label="Repita la contraseña"
            inputRef={registerPasswordRepeat}
            autoComplete="off"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={(event) => {
              if (
                status === "passwordTooShort" ||
                status === "passwordsDontMatch"
              ) {
                setStatus(null);
                handleChange(event);
              } else {
                handleChange(event);
              }
            }}
            helperText={(() => {
              if (status === "passwordTooShort") {
                return "Crea una contraseña de al menos 6 caracteres.";
              }
              if (status === "passwordsDontMatch") {
                return "Tus contraseñas no coinciden.";
              }
              return null;
            })()}
            FormHelperTextProps={{ error: true }}
            isVisible={isPasswordVisible}
            onVisibilityChange={setIsPasswordVisible}
          />
          <TextField
            variant="outlined"
            required
            margin="normal"
            fullWidth
            value={formData.edad}
            id="edad"
            onChange={handleNumericChange}
            label="Edad"
            name="edad"
          />
          <FormControl margin="normal" className={classes.formControl}>
            <InputLabel id="ciudad">Ciudad</InputLabel>
            <Select
              fullWidth
              required
              labelId="ciudad"
              id="ciudad"
              name="ciudad"
              value={formData.ciudad}
              onChange={handleChange}
            >
              {Ciudades.map((City) => {
                return (
                  <MenuItem key={City} value={City}>
                    {City}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl margin="normal" className={classes.formControl}>
            <InputLabel id="genero">Genero</InputLabel>
            <Select
              fullWidth
              required
              labelId="genero"
              id="genero"
              name="genero"
              value={formData.genero}
              onChange={handleChange}
            >
              <MenuItem value="Hombre">Hombre</MenuItem>
              <MenuItem value="Mujer">Mujer</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            required
            margin="normal"
            fullWidth
            value={formData.telefono}
            id="telefono"
            onChange={handleChange}
            label="Telefono"
            name="telefono"
          />
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={
              <Checkbox
                color="primary"
                inputRef={registerTermsCheckbox}
                onChange={() => {
                  setHasTermsOfServiceError(false);
                }}
              />
            }
            label={
              <Typography variant="body1">
                Yo acepto los
                <span
                  className={classes.link}
                  onClick={isLoading ? null : openTermsDialog}
                  tabIndex={0}
                  role="button"
                  onKeyDown={(event) => {
                    // For screenreaders listen to space and enter events
                    if (
                      (!isLoading && event.keyCode === 13) ||
                      event.keyCode === 32
                    ) {
                      openTermsDialog();
                    }
                  }}
                >
                  {" "}
                  Términos y condiciones
                </span>
              </Typography>
            }
          />
          {hasTermsOfServiceError && (
            <FormHelperText
              error
              style={{
                display: "block",
                marginTop: theme.spacing(-1),
              }}
            >
              Para crear una cuenta en este sitio, se requiere que acepte los
              terminos y condiciones.
            </FormHelperText>
          )}
          {isErrors && (
            <HighlightedInformation>
              {Object.values(isErrors).map(
                (err, i, array) => (
                  <Fragment key={i}>
                    {`*${err}`} <br /> {array.length - 1 !== i ? <br /> : ""}
                  </Fragment>
                )
              )}
            </HighlightedInformation>
          )}
        </Fragment>
      }
      actions={
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          color="secondary"
          disabled={isLoading}
        >
          Registrarse
          {isLoading && <ButtonCircularProgress />}
        </Button>
      }
    />
  );
}

RegisterDialog.propTypes = {
  theme: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  openTermsDialog: PropTypes.func.isRequired,
  status: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RegisterDialog);
