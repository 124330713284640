import React from "react";
import ReactDOM from "react-dom";
import ApolloProvider from "./ApolloProvider";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <ApolloProvider />,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
