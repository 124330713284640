import React, { useState } from 'react'
import { useMutation, gql } from "@apollo/client"

const ADD_PLAN_MUTATION = gql`
    mutation($plan: String!, $name: String!, $foto: Upload! $mainFoto: Upload) {
        addPlan(plan: $plan name: $name foto: $foto mainFoto: $mainFoto) {
            id
            mainPlan
            subPlans {
               nombre
               foto
            }
            mainFoto
        }
    }
`



export default function Plan() {
    const [variables, setVariables] = useState({ plan: "", name: "" })
    const [subir] = useMutation(ADD_PLAN_MUTATION, {
        variables: variables,
        onCompleted: (data) => console.log(data),
        onError: (err) => console.log(err)
    })
    const handleStringInput = (ev) => {
        setVariables({ ...variables, [ev.target.name]: ev.target.value })
    }
    const handleFileInput = (ev) => {
        setVariables({ ...variables, [ev.target.name]: ev.target.files[0] })
    }
    const handleSubmit = () => {
        subir();
    }

    return (
        <div className="lg-p-top">
            <input name="plan" onChange={(event) => handleStringInput(event)} />
            <input name="name" onChange={(event) => handleStringInput(event)} />
            <input name="mainFoto" type="file" onChange={(event) => handleFileInput(event)} />
            <input type="file" name="foto" onChange={(event) => handleFileInput(event)} />
            <button onClick={handleSubmit}>Subir</button>
        </div>
    )
}
