import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Card,
  withStyles,
  useMediaQuery
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ColoredButton from "../../../shared/components/ColoredButton";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import hostBack from "../../../config";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";

const styles = (theme) => ({
  termsConditionsListitem: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  dialogActions: {
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.common.ligthGray,
  },
  backIcon: {
    marginRight: theme.spacing(1),
  },
  card: {
    textDecoration: "none",
    padding: "9px",
     backgroundColor: theme.palette.secondary.main,
    "&link": {
      textDecoration: "none",
    },
  },
  body: {
    backgroundColor: theme.palette.common.ligthGray,
  },
  img: {
    width: "30%",
    borderRadius: "8px",
  },
});
const GET_ESTABLECIMIENTOS_POR_BUSQUEDA = gql`
  query ($name: String!) {
    getEstablecimientos(name: $name) {
      id
      name
      principalImage
      descripcion
    }
  }
`;
function Search(props) {
  const { classes, onClose, theme, busqueda } = props;
  const matches = useMediaQuery(theme => theme.breakpoints.down('md'));

  

  const { data, loading, error } = useQuery(GET_ESTABLECIMIENTOS_POR_BUSQUEDA, {
    variables: { name: busqueda },
  });
  
  
  return (
    <Dialog  open scroll="paper" onClose={onClose} >
      <DialogTitle className={classes.body}>Busqueda: <strong>{busqueda}</strong></DialogTitle>
      <DialogContent className={classes.body}>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <ErrorIcon />

            <Typography>{error.message}</Typography>
          </Box>
        ) : data.getEstablecimientos.length > 0 ? (
          data.getEstablecimientos.map((result, i) => (
            <Box marginTop={(i !== 0) && 2} key={i}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/establecimientos/${result.id}`}
              >
                <Card className={classes.card}>
                  <Box display="flex">
                    <img
                      src={hostBack + result.principalImage}
                      alt={result.descripcion}
                      className={classes.img}
                    />
                    <Box width="100%" marginLeft={1}>
                      <Typography variant="h4">{result.name}</Typography>

                      <LinesEllipsis
                        text={result.descripcion}
                        maxLine={matches ? 1 : 3}
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </Box>
                  </Box>
                </Card>
              </Link>
            </Box>
          ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <ErrorIcon />

            <Typography>
              No hay establecimientos registrados con su busqueda
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ColoredButton
          onClick={onClose}
          variant="contained"
          color={theme.palette.common.black}
        >
          <ArrowBackIcon className={classes.backIcon} />
          Salir
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles, { withTheme: true })(Search);
