import React, { useReducer, createContext } from "react";
import jwtDecode from "jwt-decode";

const initialState = {
  user: null,
  establecimiento: null,
  notifications: [],
  loading: true,
  error: null,
  count: null,
  ciudad: null,
};

if (localStorage.getItem("jwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("jwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("jwtToken");
  } else {
    if (decodedToken.name) {
      initialState.establecimiento = decodedToken;
      initialState.ciudad = decodedToken.ciudad;
    } else {
      initialState.user = decodedToken;
      initialState.ciudad = decodedToken.ciudad;
    }
  }
}

const AuthContext = createContext({
  user: null,
  establecimiento: null,
  notifications: null,
  error: null,
  loading: null,
  ciudad: null,
  count: null,
  setError: (err) => {},
  login: (userData) => {},
  logout: () => {},
  changeCity: (city) => {},
  setNotifications: (notify) => {},
  setCount: (count) => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN_ESTABLECIMIENTO":
      return {
        ...state,
        establecimiento: action.payload,
      };
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        establecimiento: null,
        notifications: [],
      };
    case "NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload,
        error: null,
        loading: false,
      };
    case "MODIFY_NOTIFICATIONS":
      const newer = action.payload;
      return {
        ...state,
        notifications: [
          newer,
          ...state.notifications.filter((not) => not.id !== newer.id),
        ],
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SETCOUNT":
      return {
        ...state,
        count: action.payload,
      };
    case "CHANGE_CITY":
      return {
        ...state,
        ciudad: action.payload,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function login(userData) {
    localStorage.setItem("jwtToken", userData.token);

    dispatch({
      type: "LOGIN",
      payload: userData,
    });

    window.location.href = "/";
  }
  function loginEstablecimiento(establecimientoData) {
    localStorage.setItem("jwtToken", establecimientoData.token);
    dispatch({
      type: "LOGIN_ESTABLECIMIENTO",
      payload: establecimientoData,
    });
    window.location.href = "/establecimiento/edit";
  }

  function logout() {
    localStorage.removeItem("jwtToken");
    dispatch({ type: "LOGOUT" });
    window.location.href = "/";
  }
  function setNotifications(notify) {
    dispatch({
      type: "NOTIFICATIONS",
      payload: notify,
    });
  }
  function changeCity(city) {
    dispatch({
      type: "CHANGE_CITY",
      payload: city,
    });
  }
  function updateNotifications(notify) {
    dispatch({
      type: "MODIFY_NOTIFICATIONS",
      payload: notify,
    });
  }
  function setError(err) {
    dispatch({
      type: "ERROR",
      payload: err,
    });
  }
  function setCount(count) {
    dispatch({
      type: "SETCOUNT",
      payload: count,
    });
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        establecimiento: state.establecimiento,
        notifications: state.notifications,
        loading: state.loading,
        count: state.count,
        error: state.error,
        ciudad: state.ciudad,
        loginEstablecimiento,
        setNotifications,
        login,
        logout,
        setError,
        updateNotifications,
        changeCity,
        setCount,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
