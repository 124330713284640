import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useCallback,
} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Input,
  Chip,
  Fab,
  Radio,
  FormLabel,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { DropzoneAreaBase } from "material-ui-dropzone";
import TermsOfServiceDialog from "../logged_out/components/register_login/TermsOfServiceDialog";
import HighlightedInformation from "../shared/components/HighlightedInformation";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AuthContext } from "../context/auth";

const Ciudades = [
  "Bogotá",
  "Medellín",
  "Cali",
  "Barranquilla",
  "Cartagena",
  "Bucaramanga",
  "Santa Marta",
  "Manizales",
  "Pereira",
  "Cúcuta",
  "Ibagué",
  "Villavicencio",
  "Pasto",
  "Valledupar",
  "Armenia",
  "Montería",
  "Neiva",
  "Popoyán",
  "Tunja",
  "Quibdó",
  "Florencia",
  "Sincelejo",
  "Buenaventura",
  "Riohacha",
  "Soacha",
  "Yopal",
  "Barrancabermeja",
  "Tuluá",
  "Yumbo",
  "Palmira",
  "Zipaquirá",
  "Girardot",
  "Bello",
  "Floridablanca",
  "Duitama",
  "Soledad",
  "Rionegro",
  "Tumaco",
  "Facatativá",
  "Girón",
  "Jamundí",
  "Piedecuesta",
  "Mocoa",
  "Buga",
  "Ipiales",
  "Envigado",
  "Letecia",
  "Apartadó",
  "Arauca",
  "San José del Guavire",
  "Cartago",
];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const RedRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    "&$checked": {
      color: theme.palette.secondary.main,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dropzone: {
    backgroundColor: theme.palette.secondary.main,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      opacity: 0.9,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  link: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
}));
const GET_CATEGORIAS_QUERY = gql`
  {
    getCategorias {
      nombre
    }
  }
`;
const REGISTER_ESTABLECIMIENTO_MUTATION = gql`
  mutation ($EstablecimientoInput: EstablecimientoInput!) {
    registerEstablecimento(establecimientoInput: $EstablecimientoInput) {
      token
      id
    }
  }
`;

export default function SignUp(props) {
  const { loginEstablecimiento, user } = useContext(AuthContext);

  const matches = useMediaQuery("(min-width:600px)");

  const classes = useStyles();
  const [categorias, setCategorias] = useState([]);
  const [Contactos, setContactos] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [Ubicacion, setUbicacion] = useState([]);

  const { loading } = useQuery(GET_CATEGORIAS_QUERY, {
    onCompleted: (data) => setCategorias(data.getCategorias),
  });
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = useState(false);
  const [Restricciones, setRestricciones] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    contacto: {},
    descripcion: "",
    email: "",
    password: "",
    confirmPassword: "",
    ubicacion: {},
    ciudad: "",
    restriccion: [],
    categoria: [],
    principalImage: null,
    personaCargo: "",
    celular: "",
    correo: "",
    empresa: "",
    NIT: "",
    reservas: {
      hours: [],
      avaible: false,
      days: [],
    },
  });
  const [registerEstablecimiento, { loading: loadingMutation }] = useMutation(
    REGISTER_ESTABLECIMIENTO_MUTATION,
    {
      onCompleted: (data) => {
        loginEstablecimiento(data.registerEstablecimento);
      },
      variables: { EstablecimientoInput: formData },
      onError: (err) => {
        setErrors({
          ...errors,
          ...(err.graphQLErrors[0].extensions.errors || err),
        });
      },
    }
  );

  useEffect(() => {
    let newValue = formData;

    const fakeContacto = Object.keys(newValue["contacto"]).filter(
      (tipo) => !Contactos.includes(tipo)
    );
    fakeContacto.forEach((fake) => {
      delete newValue.contacto[fake];
    });
    Contactos.forEach((tipo) => {
      newValue = {
        ...newValue,
        contacto: { ...newValue.contacto, [tipo]: "" },
      };
    });

    setFormData(newValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Contactos]);
  useEffect(() => {
    let newValue = formData;

    const fakeLocal = Object.keys(newValue["ubicacion"]).filter(
      (tipo) => !Ubicacion.includes(tipo)
    );
    fakeLocal.forEach((fake) => {
      delete newValue.ubicacion[fake];
    });
    Ubicacion.forEach((tipo) => {
      newValue = {
        ...newValue,
        ubicacion: { ...newValue.ubicacion, [tipo]: "" },
      };
    });

    setFormData(newValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Ubicacion]);
  useEffect(() => {
    let newValue = formData;

    const fakeRestriccion = Object.keys(newValue["restriccion"]).filter(
      (tipo) => !Restricciones.includes(tipo)
    );
    fakeRestriccion.forEach((fake) => {
      delete newValue.restriccion[fake];
    });

    Restricciones.forEach((tipo) => {
      if (tipo !== "Personalizada") {
        if (!formData.restriccion[tipo]) {
          newValue = {
            ...newValue,
            restriccion: {
              ...newValue.restriccion,
              [tipo]: { restringe: false, data: "" },
            },
          };
        }
      } else {
        if (!formData.restriccion[tipo]) {
          newValue = {
            ...newValue,
            restriccion: {
              ...newValue.restriccion,
              [tipo]: { restringe: false, nombre: "", data: "" },
            },
          };
        }
      }
    });

    setFormData(newValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Restricciones]);

  const handleChange = useCallback(
    (event) => {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    },
    [setFormData, formData]
  );
  const handleTipoContacto = useCallback(
    (event) => {
      setContactos(event.target.value);
    },
    [setContactos]
  );
  const handleMultipleChange = useCallback(
    (event) => {
      const values = event.target.value;
      if (values.length > 3) {
        values.shift();
      }
      setFormData({ ...formData, [event.target.name]: values });
    },
    [formData]
  );
  const handleFileChange = useCallback(
    (files) => {
      setFormData({ ...formData, principalImage: files[0].file });
    },
    [formData]
  );

  const openTermsDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };
  const caseSwitch = (contacto) => {
    switch (contacto) {
      case "Celular":
        return "Ingrese el numero de tlf del contacto";
      default:
        return `Ingrese la url de su contacto ${contacto}`;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checked) {
      setErrors({
        terminos_y_condiciones:
          "Debe aceptar los terminos y condiciones para poder registrar su establecimiento",
      });
    } else {
      setErrors({});
    }

    registerEstablecimiento();
  };
  if (user) return <Redirect to="/" />;
  return (
    <Fragment>
      {dialogOpen && <TermsOfServiceDialog onClose={closeDialog} />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registro de establecimiento
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  variant="outlined"
                  value={formData.name}
                  required
                  fullWidth
                  id="name"
                  onChange={handleChange}
                  label="Nombre del establecimiento"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  multiline
                  rows={4}
                  fullWidth
                  onChange={handleChange}
                  id="descripcion"
                  value={formData.descripcion}
                  label="Describe tu establecimiento"
                  name="descripcion"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.email}
                  id="email"
                  onChange={handleChange}
                  label="Correo electronico"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  onChange={handleChange}
                  value={formData.password}
                  label="Contraseña"
                  type="password"
                  id="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  value={formData.confirmPassword}
                  fullWidth
                  name="confirmPassword"
                  onChange={handleChange}
                  label="Confirmar contraseña"
                  type="password"
                  id="confirmPassword"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="ubicacion">Ubicación</InputLabel>
                <FormControl className={classes.formControl}>
                  <Select
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    input={<Input id="ubicacion" />}
                    value={Ubicacion}
                    onChange={(event) => setUbicacion(event.target.value)}
                    multiple
                    fullWidth
                    required
                    labelId="ubicacion"
                    id="ubicacion"
                    name="ubicacion"
                  >
                    <MenuItem value="Google_maps">Google maps</MenuItem>
                    <MenuItem value="Waze">Waze</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {Ubicacion.map((local) => (
                <Grid item xs={12} key={local}>
                  <FormControl>
                    <FormLabel>
                      Debe ingresar el link de la dirección de su
                      establecimiento en {local}. Puede buscar su dirección{" "}
                      <a
                        href={
                          local === "Google_maps"
                            ? "https://www.google.com/maps/?hl=es"
                            : "https://www.waze.com/es/live-map"
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        aqui
                      </a>
                    </FormLabel>
                    <br />
                    <TextField
                      name={local}
                      variant="outlined"
                      required
                      fullWidth
                      value={formData.ubicacion[local]}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          ubicacion: {
                            ...formData.ubicacion,
                            [local]: event.target.value,
                          },
                        })
                      }
                      id={local}
                      label={
                        local === "Google_maps"
                          ? "https://www.google.com/maps/?hl=es"
                          : "https://www.waze.com/es/live-map"
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
              <Grid item xs={12}>
                <InputLabel id="contacto">Tipo de contacto</InputLabel>
                <FormControl className={classes.formControl}>
                  <Select
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    input={<Input id="restricciones" />}
                    value={Contactos}
                    onChange={handleTipoContacto}
                    multiple
                    fullWidth
                    required
                    labelId="contacto"
                    id="contacto"
                    name="contacto"
                  >
                    <MenuItem value="Facebook">Facebook</MenuItem>
                    <MenuItem value="Instagram">Instagram</MenuItem>
                    <MenuItem value="Whatsapp">Whatsapp</MenuItem>
                    <MenuItem value="Celular">Celular</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {Contactos.map((contact) => (
                <Grid item xs={12} key={contact}>
                  <TextField
                    name={contact}
                    variant="outlined"
                    required
                    fullWidth
                    value={formData.contacto[contact]}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        contacto: {
                          ...formData.contacto,
                          [contact]: event.target.value,
                        },
                      })
                    }
                    id={contact}
                    label={caseSwitch(contact)}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="ciudad">Ciudad</InputLabel>
                  <Select
                    fullWidth
                    required
                    labelId="ciudad"
                    id="ciudad"
                    name="ciudad"
                    value={formData.ciudad}
                    onChange={handleChange}
                  >
                    {Ciudades.map((City) => {
                      return (
                        <MenuItem key={City} value={City}>
                          {City}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="restriccion">Restricciones</InputLabel>
                  <Select
                    labelId="restriccion"
                    id="restriccion"
                    multiple
                    fullWidth
                    required
                    name="restriccion"
                    value={Restricciones}
                    onChange={(event) => setRestricciones(event.target.value)}
                    input={<Input id="restriccion" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    <MenuItem value="Mascotas">Mascotas</MenuItem>
                    <MenuItem value="Fumar">Fumar</MenuItem>
                    <MenuItem value="Horario">Horario</MenuItem>
                    <MenuItem value="Ropa">Ropa</MenuItem>
                    <MenuItem value="Edad">Edad</MenuItem>
                    <MenuItem value="Numero_de_personas">
                      Numero de personas
                    </MenuItem>
                    <MenuItem value="Personalizada">Personalizada </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {Object.keys(formData.restriccion).map((restric) => {
                if (restric !== "Personalizada") {
                  return (
                    <Grid item xs={12} key={restric}>
                      <br />

                      <FormControl>
                        <FormLabel component="legend">
                          Indique si su establecimiento aplica alguna
                          restricción sobre: <strong>{restric}</strong>
                        </FormLabel>
                        <RadioGroup
                          value={formData.restriccion[restric].restringe}
                          onChange={() =>
                            setFormData({
                              ...formData,
                              restriccion: {
                                ...formData.restriccion,
                                [restric]: {
                                  ...formData.restriccion[restric],
                                  restringe:
                                    !formData.restriccion[restric].restringe,
                                },
                              },
                            })
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<RedRadio />}
                            label="Si"
                          />
                          <FormControlLabel
                            value={false}
                            control={<RedRadio />}
                            label="No"
                          />
                        </RadioGroup>
                        {formData.restriccion[restric].restringe && (
                          <TextField
                            name={restric}
                            variant="outlined"
                            required
                            fullWidth
                            value={formData.restriccion[restric].data}
                            onChange={(event) =>
                              setFormData({
                                ...formData,
                                restriccion: {
                                  ...formData.restriccion,
                                  [restric]: {
                                    ...formData.restriccion[restric],
                                    data: event.target.value,
                                  },
                                },
                              })
                            }
                            id={restric}
                            label={"Describa la restricción"}
                          />
                        )}
                      </FormControl>
                    </Grid>
                  );
                } else {
                  return (
                    <Fragment key={restric}>
                      <Grid item xs={12}>
                        <br />
                        <TextField
                          name={restric}
                          variant="outlined"
                          required
                          fullWidth
                          value={formData.restriccion[restric].nombre}
                          onChange={(event) =>
                            setFormData({
                              ...formData,
                              restriccion: {
                                ...formData.restriccion,
                                [restric]: {
                                  ...formData.restriccion[restric],
                                  nombre: event.target.value,
                                },
                              },
                            })
                          }
                          id={restric}
                          label={"Nombre de su restricción personalizada"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <br />
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Indique si su establecimiento aplica alguna
                            restricción sobre:{" "}
                            <strong>
                              {formData.restriccion[restric].nombre !== ""
                                ? formData.restriccion[restric].nombre
                                : "Restricción personalizada innombrada"}
                            </strong>
                          </FormLabel>
                          <RadioGroup
                            value={formData.restriccion[restric].restringe}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                restriccion: {
                                  ...formData.restriccion,
                                  [restric]: {
                                    ...formData.restriccion[restric],
                                    restringe:
                                      !formData.restriccion[restric].restringe,
                                  },
                                },
                              })
                            }
                          >
                            <FormControlLabel
                              value={true}
                              control={<RedRadio />}
                              label="Si"
                            />
                            <FormControlLabel
                              value={false}
                              control={<RedRadio />}
                              label="No"
                            />
                          </RadioGroup>
                          {formData.restriccion[restric].restringe && (
                            <TextField
                              name={restric}
                              variant="outlined"
                              required
                              fullWidth
                              value={formData.restriccion[restric].data}
                              onChange={(event) =>
                                setFormData({
                                  ...formData,
                                  restriccion: {
                                    ...formData.restriccion,
                                    [restric]: {
                                      ...formData.restriccion[restric],
                                      data: event.target.value,
                                    },
                                  },
                                })
                              }
                              id={restric}
                              label={"Describa la restricción"}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    </Fragment>
                  );
                }
              })}
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="categoria">Categorias</InputLabel>
                  <Select
                    labelId="categoria"
                    id="categoria"
                    aria-valuemax={3}
                    multiple
                    fullWidth
                    name="categoria"
                    required
                    value={formData.categoria}
                    onChange={handleMultipleChange}
                    input={<Input id="categoria" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {loading ? (
                      <MenuItem disable>Cargando</MenuItem>
                    ) : (
                      categorias.map((categoria) => (
                        <MenuItem
                          value={categoria.nombre}
                          key={categoria.nombre}
                        >
                          {categoria.nombre}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Indique si su establecimiento acepta reservas de usuarios.
                  </FormLabel>
                  <RadioGroup
                    value={formData.reservas.avaible}
                    onChange={(ev) =>
                      setFormData({
                        ...formData,
                        reservas: {
                          days: [],
                          avaible: !formData.reservas.avaible,
                          hours: []
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<RedRadio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value={false}
                      control={<RedRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formData.reservas.avaible && (
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="restriccion">Dias no laborales</InputLabel>
                    <Select
                      labelId="restriccion"
                      id="restriccion"
                      multiple
                      fullWidth
                      aria-valuemax={6}
                      required
                      name="restriccion"
                      value={formData.reservas.days}
                      onChange={(event) => {
                        const values = event.target.value;
                        if (values.length > 6) {
                          values.shift();
                        }
                        setFormData({
                          ...formData,
                          reservas: {
                            ...formData.reservas,
                            days: values,
                          },
                        });
                      }}
                      input={<Input id="restriccion" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                    >
                      <MenuItem value="Lunes">Lunes</MenuItem>
                      <MenuItem value="Martes">Martes</MenuItem>
                      <MenuItem value="Miercoles">Miercoles</MenuItem>
                      <MenuItem value="Jueves">Jueves</MenuItem>
                      <MenuItem value="Viernes">Viernes</MenuItem>
                      <MenuItem value="Sabado">Sabado</MenuItem>
                      <MenuItem value="Domingo">Domingo </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="horas">Horas no laborales</InputLabel>
                    <Select
                      labelId="horas"
                      id="horas"
                      multiple
                      fullWidth
                      required
                      name="horas"
                      value={formData.reservas.hours}
                      onChange={(event) => {
                        const values = event.target.value;
                        if (values.length > 37) {
                          values.shift();
                        }
                        setFormData({
                          ...formData,
                          reservas: {
                            ...formData.reservas,
                            hours: values,
                          },
                        });
                      }}
                      input={<Input id="horas" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                    >
                      <MenuItem value="07:0">7:00 am</MenuItem>
                      <MenuItem value="07:30">7:30 am</MenuItem>
                      <MenuItem value="08:0">8:00 am</MenuItem>
                      <MenuItem value="8:30">8:30 am</MenuItem>
                      <MenuItem value="9:00">9:00 am</MenuItem>
                      <MenuItem value="9:30">9:30 am</MenuItem>
                      <MenuItem value="10:00">10:00 am </MenuItem>
                      <MenuItem value="10:30">10:30 am</MenuItem>
                      <MenuItem value="11:00">11:00 am</MenuItem>
                      <MenuItem value="11:30">11:30 am</MenuItem>
                      <MenuItem value="12:00">12:00 pm</MenuItem>
                      <MenuItem value="12:30">12:30 pm</MenuItem>
                      <MenuItem value="13:00">1:00 pm</MenuItem>
                      <MenuItem value="13:30">1:30 pm </MenuItem>
                      <MenuItem value="14:00">2:00 pm</MenuItem>
                      <MenuItem value="14:30">2:30 pm</MenuItem>
                      <MenuItem value="15:00">3:00 pm</MenuItem>
                      <MenuItem value="15:30">3:30 pm</MenuItem>
                      <MenuItem value="16:00">4:00 pm</MenuItem>
                      <MenuItem value="16:30">4:30 pm</MenuItem>
                      <MenuItem value="17:00">5:00 pm </MenuItem>
                      <MenuItem value="17:30">5:30 pm</MenuItem>
                      <MenuItem value="18:00">6:00 pm</MenuItem>
                      <MenuItem value="18:30">6:30 pm</MenuItem>
                      <MenuItem value="19:00">7:00 pm</MenuItem>
                      <MenuItem value="19:30">7:30 pm</MenuItem>
                      <MenuItem value="20:00">8:00 pm</MenuItem>
                      <MenuItem value="20:30">8:30 pm </MenuItem>
                      <MenuItem value="21:00">9:00 pm</MenuItem>
                      <MenuItem value="21:30">9:30 pm</MenuItem>
                      <MenuItem value="22:00">10:00 pm</MenuItem>
                      <MenuItem value="22:30">10:30 pm</MenuItem>
                      <MenuItem value="23:00">11:00 pm </MenuItem>
                      <MenuItem value="23:30">11:30 pm</MenuItem>
                      <MenuItem value="0:00">12:00 am</MenuItem>
                      <MenuItem value="0:30">12:30 am</MenuItem>
                      <MenuItem value="1:00">1:00 am</MenuItem>
                      <MenuItem value="1:30">1:30 am</MenuItem>
                      <MenuItem value="2:00">2:00 am</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <DropzoneAreaBase
                  acceptedFiles={["image/*"]}
                  dropzoneText={"Subir imagen principal de presentación"}
                  onAdd={(files) => handleFileChange(files)}
                  dropzoneClass={classes.dropzone}
                  dropzoneParagraphClass="text-white"
                  filesLimit={1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.personaCargo}
                  id="personaCargo"
                  onChange={handleChange}
                  label="Nombre de la persona a cargo"
                  name="personaCargo"
                  autoComplete="personaCargo"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.celular}
                  id="celular"
                  onChange={handleChange}
                  label="Celular de contacto para facturación"
                  name="celular"
                  autoComplete="celular"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.correo}
                  id="correo"
                  onChange={handleChange}
                  label="Correo electrónico para facturación"
                  name="correo"
                  autoComplete="correo"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.empresa}
                  id="empresa"
                  onChange={handleChange}
                  label="Nombre de la empresa"
                  name="empresa"
                  autoComplete="empresa"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={formData.NIT}
                  id="NIT"
                  onChange={handleChange}
                  label="NIT"
                  name="NIT"
                  autoComplete="NIT"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={checked}
                      onChange={() => setChecked(!checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body1">
                      Yo acepto los
                      <span
                        className={classes.link}
                        onClick={openTermsDialog}
                        tabIndex={0}
                        role="button"
                        onKeyDown={(event) => {
                          // For screenreaders listen to space and enter events
                          if (event.keyCode === 13 || event.keyCode === 32) {
                            openTermsDialog();
                          }
                        }}
                      >
                        {" "}
                        Términos y condiciones
                      </span>
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            {Object.keys(errors).length !== 0 && (
              <HighlightedInformation>
                {Object.entries(errors).map(([, err], i, array) => {
                  return (
                    <Fragment key={i}>
                      {`*${err}`} <br /> {array.length - 1 !== i ? <br /> : ""}
                    </Fragment>
                  );
                })}
              </HighlightedInformation>
            )}
            <Button
              type="submit"
              fullWidth
              disabled={
                formData.principalImage === null || loading || loadingMutation
              }
              variant="contained"
              className={classes.submit}
            >
              Registrarse
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link to="/establecimiento/login" variant="body2">
                  ¿Tu establecimiento ya tiene una cuenta?, ingresa aquí
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
        <Link to="/">
          <Fab size={matches ? "medium" : "small"} className={classes.fab}>
            <ArrowBackIcon />
          </Fab>
        </Link>
      </Container>
    </Fragment>
  );
}
