import React, { useEffect, useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../../context/auth";
const GET_NOTIFICACIONES_QUERY = gql`
  {
    getNotificacionesUser {
      id
      to
      message
      title
      reserva {
        user {
          id
          username
          telefono
        }
        establecimiento {
          id
          name
        }
        createdAt
        status
      }
      from
      createdAt
      global {
        isEstablecimiento
      }
    }
  }
`;
const GET_NOTIFICACIONES_SUBSCRIPTION = gql`
  subscription {
    newNotificaciones {
      id
      to
      message
      title
      reserva {
        user {
          id
          username
          telefono
        }
        establecimiento {
          id
          name
        }
        createdAt
        status
      }
      from
      createdAt
      global {
        isEstablecimiento
      }
    }
  }
`;
const GET_USER_COUNT = gql`
   {
  getUserCount
}
`
export default function LoadNotifications() {
  const { setNotifications, setError, setCount } = useContext(AuthContext);
  const { subscribeToMore, data, error } = useQuery(
    GET_NOTIFICACIONES_QUERY,
    {
      onCompleted: () => {
        setNotifications(data.getNotificacionesUser);
      },
      onError: () => setError(error),
    }
  );
  const {data: note} = useQuery(GET_USER_COUNT, {
    onCompleted: () => {
      setCount(note.getUserCount);
    },
    onError: (err) => setError(err),
  })

  useEffect(() => {
    let unsubscribe = subscribeToMore({
      document: GET_NOTIFICACIONES_SUBSCRIPTION,

      onError: (err) => setError(err),
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const subscriptionResponse = subscriptionData.data.newNotificaciones;
        const formatedPrev = prev.getNotificacionesUser.filter(
          (not) => not.id !== subscriptionResponse.id
        );
        const newCache = Object.assign({}, formatedPrev, {
          getNotificacionesUser: [subscriptionResponse, ...formatedPrev],
        });
        setNotifications(newCache.getNotificacionesUser);

        return newCache;
      },
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeToMore]);
  return <div></div>;
}
