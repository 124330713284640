import React, { memo, useState, useRef, useEffect } from "react";
import { Grid, Typography, Box, Button, withStyles } from "@material-ui/core";
import hostBack from "../config";
import ReadMore from "@sstraatemans/react-readmore";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import Carousel from "react-elastic-carousel";
const DELETE_IMG_PHOTO = gql`
  mutation ($nombre: String!) {
    deleteImageFromEstablecimiento(nombre: $nombre, tipo: "fotos_y_videos") {
      id
      fotos_y_videos
    }
  }
`;
const styles = (theme) => ({
  img: {
    width: "55%",
    float: "left",
    marginRight: "1.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imgMovil: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  bordered: {
    borderRadius: 18,
    width: "100%",
    height: "100%",
    userDrag: "none",
  },
});
const RedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "56px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "0.8",
    },
  },
}))(Button);

function Principal(props) {
  const {
    classes,
    establecimiento,
    setPrincipalEdit,
    matches,
    edited,
    callback,
    setDescripcionEdit,
  } = props;
  const [lines, setLines] = useState(1);
  const height = useRef(null);
  const settings = {
    pagination: false,
    enableAutoPlay: false,
    showArrows: false,
  };
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (height.current) {
      setLines(
        Math.round(
          (height.current.offsetHeight * height.current.offsetHeight) / 110
        )
      );
    }
  }, [height]);
  const [deletePhoto] = useMutation(DELETE_IMG_PHOTO);
  return (
    <Grid item xs={12} md={10}>
      <Typography align={matches ? "center" : "inherit"} variant="h3">
        {establecimiento.name}
      </Typography>
      <Box style={{ textAlign: "justify", fontSize: "20px" }}>
        <div className={!matches ? classes.img : classes.imgMovil}>
          <Carousel {...settings}>
            <Box>
              <img
                className={classes.bordered}
                src={hostBack + establecimiento.principalImage}
                alt="Imagen principal del establecimiento"
                ref={height}
                style={edited ? { height: "calc(100% - 120px)" } : {}}
              />
              {edited && (
                <Box display="flex" justifyContent="center">
                  <Box mr={3}>
                    <RedButton onClick={() => setPrincipalEdit(true)}>
                      Cambiar imagen
                    </RedButton>
                  </Box>
                  <Box>
                    <RedButton onClick={() => callback(true)}>
                      Añadir foto o video
                    </RedButton>
                  </Box>
                </Box>
              )}
            </Box>
            {establecimiento.fotos_y_videos.map((tile, i) => (
              <Box width="100%" height="100%" key={i}>
                {tile.split(".")[1] === "mp4" ? (
                  <video
                    src={hostBack + tile}
                    className={classes.bordered}
                    alt={
                      "fotos o video demostrativo de los servicios o aspecto del establecimiento"
                    }
                    style={edited ? { height: "calc(100% - 120px)" } : {}}
                  />
                ) : (
                  <img
                    src={hostBack + tile}
                    className={classes.bordered}
                    alt={
                      "fotos o video demostrativo de los servicios o aspecto del establecimiento"
                    }
                    style={edited ? { height: "calc(100% - 120px)" } : {}}
                  />
                )}
                {edited && (
                  <Box display="flex" justifyContent="center">
                    <Box mr={3}>
                      <RedButton
                        onClick={() =>
                          deletePhoto({ variables: { nombre: tile } })
                        }
                      >
                        Borrar
                      </RedButton>
                    </Box>
                    <Box>
                      <RedButton onClick={() => callback(true)}>
                        Añadir
                      </RedButton>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          </Carousel>
        </div>
        <ReadMore
          maxLines={!edited ? lines : 5}
          readMoreLabel="Leer mas"
          readLessLabel="Leer menos"
        >
          {establecimiento.descripcion}
        </ReadMore>
        {edited && (
          <Box display="flex" mt={1} justifyContent="center">
            <RedButton onClick={() => setDescripcionEdit(true)}>
              Cambiar texto
            </RedButton>
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Principal));
