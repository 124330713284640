import React, { memo, useState, forwardRef, Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  withStyles,
  Button,
  Slide,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Paper,
  Chip,
  Input,
  MenuItem,
  Select,
  Radio,
} from "@material-ui/core";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { addDays, addMonths, setHours, setMinutes } from "date-fns";

const styles = (theme) => ({
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
    color: "#fff",
  },
  borderMinus: {
    borderRadius: 14,
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: theme.palette.common.ligthGray,
    minHeight: "56px",
    "&:hover": {
      backgroundColor: theme.palette.common.darkGray,
    },
  },
}))(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MAKE_PETITION_MUTATION = gql`
  mutation ($estID: String!, $personas: Int!, $fecha: String!) {
    hacerPeticion(estID: $estID, personas: $personas, fecha: $fecha) {
      id
      to
      message
      title
      reserva {
        user {
          id
          username
          telefono
        }
        establecimiento {
          id
          name
        }
        createdAt
        status
      }
      from
      createdAt
      global {
        isEstablecimiento
      }
    }
  }
`;

const EDIT_RESERVA_MUTATION = gql`
  mutation ($reservas: ReservaEstInput!) {
    updateEstablecimiento(establecimientoUpdate: { reservas: $reservas }) {
      id
      reservas {
        days
        avaible
        hours
      }
    }
  }
`;

const RedRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    "&$checked": {
      color: theme.palette.secondary.main,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const RedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "56px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "0.8",
    },
  },
}))(Button);
function Reserva(props) {
  const { classes, onClose, edited, blockDays, reserva, estID } = props;
  registerLocale("es", es); // register it with the name you want
  const [formData, setFormData] = useState({
    avaible: reserva.avaible,
    days: reserva.days || [],
    hours: reserva.hours || [],
  });

  const dayController = (dates) => {
    const array = [];
    dates.forEach((day) => {
      switch (day) {
        case "Lunes":
          array.push(1);
          break;
        case "Martes":
          array.push(2);
          break;
        case "Miercoles":
          array.push(3);
          break;
        case "Jueves":
          array.push(4);
          break;
        case "Viernes":
          array.push(5);
          break;
        case "Sabado":
          array.push(6);
          break;
        case "Domingo":
          array.push(0);
          break;
        default:
          break;
      }
    });
    return array;
  };
  const dayStart = (date) => {
    if (blockDays) {
      let day = date.getDay();
      const filter = dayController(blockDays);
      let i = 0;
      for (; filter.includes(day); i++) {
        if (day === 6) {
          day = 0;
        }
        day++;
      }

      return i;
    } else {
      return 0;
    }
  };
  const excluded = [
    ...reserva.hours.map((hora) => {
      const hour = parseInt(hora.split(":")[0]);
      const min = parseInt(hora.split(":")[1]);

      return setHours(setMinutes(new Date(), min), hour);
    }),
  ];
  const [personas, setPersonas] = useState(0);
  const [startDate, setStartDate] = useState(
    addDays(new Date(), dayStart(new Date()))
  );
  const [makeReserva, { loading: mutationLoading }] = useMutation(
    MAKE_PETITION_MUTATION,
    {
      onCompleted: () => onClose(),
      onError: (err) => alert(err),
      variables: { estID, fecha: startDate, personas },
    }
  );
  const [changeReserva] = useMutation(EDIT_RESERVA_MUTATION, {
    onCompleted: () => onClose(),
    onError: (err) => alert(err),
    variables: { reservas: formData },
  });
  const [open, setOpen] = useState(false);
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <ColorButton className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </ColorButton>
  ));

  const isWeekday = (date) => {
    if (blockDays) {
      const day = date.getDay();
      const filter = dayController(blockDays);
      return !filter.includes(day);
    } else {
      return true;
    }
  };

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {!edited ? (
        <Fragment>
          <DialogTitle className={classes.dialog}>
            Ingrese la fecha de su reserva
          </DialogTitle>
          <DialogContent
            style={open ? { minHeight: "380px" } : {}}
            className={classes.dialog}
          >
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <DatePicker
                selected={startDate}
                open={open}
                dateFormat="dd/MM/yyyy"
                showPopperArrow={false}
                onCalendarOpen={() => setOpen(true)}
                onCalendarClose={() => setOpen(false)}
                minDate={new Date()}
                maxDate={addMonths(new Date(), 3)}
                showTimeSelect
                excludeTimes={excluded}
                onChange={(date) => setStartDate(date)}
                customInput={<ExampleCustomInput />}
                locale="es"
                filterDate={isWeekday}
                calendarStartDay={4}
              />
            </Box>
            <Typography align="center">Numero de personas:</Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                onClick={() =>
                  setPersonas(
                    personas !== 0
                      ? personas - 1 > 0
                        ? personas - 1
                        : personas
                      : 1
                  )
                }
                color="default"
              >
                <RemoveIcon />
              </Button>
              <Typography align="center">{personas}</Typography>
              <Button
                onClick={() =>
                  setPersonas(
                    personas !== 0
                      ? personas + 1 < 30
                        ? personas + 1
                        : personas
                      : 1
                  )
                }
                color="default"
              >
                <AddIcon />
              </Button>
            </Box>
          </DialogContent>

          <DialogActions className={classes.dialog}>
            <ColorButton className={classes.borderMinus} onClick={onClose}>
              Cancelar
            </ColorButton>
            <ColorButton
              disabled={personas === 0 || mutationLoading}
              className={classes.borderMinus}
              onClick={makeReserva}
            >
              Reservar
            </ColorButton>
          </DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          <DialogTitle>Edición de reservas</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Indique si su establecimiento acepta reservas de usuarios.
              </FormLabel>
              <RadioGroup
                value={formData.avaible}
                onChange={(ev) =>
                  setFormData({
                    ...formData,
                    avaible: !formData.avaible,
                  })
                }
              >
                <FormControlLabel
                  value={true}
                  control={<RedRadio />}
                  label="Si"
                />
                <FormControlLabel
                  value={false}
                  control={<RedRadio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formData.avaible && (
              <Fragment>
                <FormControl className={classes.formControl}>
                  <InputLabel id="restriccion">Dias no laborales</InputLabel>
                  <Select
                    labelId="restriccion"
                    id="restriccion"
                    multiple
                    fullWidth
                    aria-valuemax={6}
                    required
                    name="restriccion"
                    value={formData.days}
                    onChange={(event) => {
                      const values = event.target.value;
                      if (values.length > 6) {
                        values.shift();
                      }
                      setFormData({
                        ...formData,
                        days: values,
                      });
                    }}
                    input={<Input id="restriccion" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    <MenuItem value="Lunes">Lunes</MenuItem>
                    <MenuItem value="Martes">Martes</MenuItem>
                    <MenuItem value="Miercoles">Miercoles</MenuItem>
                    <MenuItem value="Jueves">Jueves</MenuItem>
                    <MenuItem value="Viernes">Viernes</MenuItem>
                    <MenuItem value="Sabado">Sabado</MenuItem>
                    <MenuItem value="Domingo">Domingo </MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="horas">Horas no laborales</InputLabel>
                  <Select
                    labelId="horas"
                    id="horas"
                    multiple
                    fullWidth
                    required
                    name="horas"
                    value={formData.hours}
                    onChange={(event) => {
                      const values = event.target.value;
                      if (values.length > 37) {
                        values.shift();
                      }
                      setFormData({
                        ...formData,
                        hours: values,
                      });
                    }}
                    input={<Input id="horas" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    <MenuItem value="07:0">7:00 am</MenuItem>
                    <MenuItem value="07:30">7:30 am</MenuItem>
                    <MenuItem value="08:0">8:00 am</MenuItem>
                    <MenuItem value="8:30">8:30 am</MenuItem>
                    <MenuItem value="9:00">9:00 am</MenuItem>
                    <MenuItem value="9:30">9:30 am</MenuItem>
                    <MenuItem value="10:00">10:00 am </MenuItem>
                    <MenuItem value="10:30">10:30 am</MenuItem>
                    <MenuItem value="11:00">11:00 am</MenuItem>
                    <MenuItem value="11:30">11:30 am</MenuItem>
                    <MenuItem value="12:00">12:00 pm</MenuItem>
                    <MenuItem value="12:30">12:30 pm</MenuItem>
                    <MenuItem value="13:00">1:00 pm</MenuItem>
                    <MenuItem value="13:30">1:30 pm </MenuItem>
                    <MenuItem value="14:00">2:00 pm</MenuItem>
                    <MenuItem value="14:30">2:30 pm</MenuItem>
                    <MenuItem value="15:00">3:00 pm</MenuItem>
                    <MenuItem value="15:30">3:30 pm</MenuItem>
                    <MenuItem value="16:00">4:00 pm</MenuItem>
                    <MenuItem value="16:30">4:30 pm</MenuItem>
                    <MenuItem value="17:00">5:00 pm </MenuItem>
                    <MenuItem value="17:30">5:30 pm</MenuItem>
                    <MenuItem value="18:00">6:00 pm</MenuItem>
                    <MenuItem value="18:30">6:30 pm</MenuItem>
                    <MenuItem value="19:00">7:00 pm</MenuItem>
                    <MenuItem value="19:30">7:30 pm</MenuItem>
                    <MenuItem value="20:00">8:00 pm</MenuItem>
                    <MenuItem value="20:30">8:30 pm </MenuItem>
                    <MenuItem value="21:00">9:00 pm</MenuItem>
                    <MenuItem value="21:30">9:30 pm</MenuItem>
                    <MenuItem value="22:00">10:00 pm</MenuItem>
                    <MenuItem value="22:30">10:30 pm</MenuItem>
                    <MenuItem value="23:00">11:00 pm </MenuItem>
                    <MenuItem value="23:30">11:30 pm</MenuItem>
                    <MenuItem value="0:00">12:00 am</MenuItem>
                    <MenuItem value="0:30">12:30 am</MenuItem>
                    <MenuItem value="1:00">1:00 am</MenuItem>
                    <MenuItem value="1:30">1:30 am</MenuItem>
                    <MenuItem value="2:00">2:00 am</MenuItem>
                  </Select>
                </FormControl>
              </Fragment>
            )}
          </DialogContent>
        </Fragment>
      )}
      {edited && (
        <DialogActions>
          <RedButton
            className={classes.borderMinus}
            onClick={onClose}
            color="default"
            autoFocus
          >
            Cancelar
          </RedButton>
          <RedButton
            className={classes.borderMinus}
            onClick={changeReserva}
            color="default"
            autoFocus
          >
            Editar
          </RedButton>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Reserva));
