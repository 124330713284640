import React, { memo, useState, Fragment } from "react";
import {
  TextField,
  FormLabel,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  MenuItem,
  Box,
  Select,
  InputLabel,
  FormControl,
  Chip,
  Input,
  withStyles,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import HighlightedInformation from "../..//shared/components/HighlightedInformation";

const EDIT_DESCRIPTION_MUTATION = gql`
  mutation ($ubicacion: UbicacionInput!) {
    updateEstablecimiento(establecimientoUpdate: { ubicacion: $ubicacion }) {
      id
      ubicacion {
        Google_maps
        Waze
      }
    }
  }
`;
const EDIT_CONTACTO_MUTATION = gql`
  mutation ($contacto: inputRedes!) {
    updateEstablecimiento(establecimientoUpdate: { contacto: $contacto }) {
      id
      contacto {
        Facebook
        Instagram
        Celular
        Whatsapp
      }
    }
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
  },
  whiteInput: {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  borderMinus: {
    borderRadius: 14,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: theme.palette.common.ligthGray,
    minHeight: "56px",
    "&:hover": {
      backgroundColor: theme.palette.common.darkGray,
    },
  },
}))(Button);

function Ubicacion(props) {
  const { classes, callback, ubicacion, type } = props;

  const [errors, setErrors] = useState({});
  const [Ubicacion, setUbicacion] = useState(
    ubicacion
      ? Object.keys(ubicacion).filter(
          (key) => key !== "__typename" && ubicacion[key]
        )
      : []
  );
  const [formData, setFormData] = useState(
    ubicacion
      ? Object.keys(ubicacion)
          .filter((key) => key !== "__typename" && ubicacion[key])
          .reduce((res, key) => ((res[key] = ubicacion[key]), res), {})
      : {}
  );

  const [editUbicacion] = useMutation(EDIT_DESCRIPTION_MUTATION, {
    variables: { ubicacion: formData },
    onCompleted: () => callback(),
    onError: (err) =>
      setErrors({
        ...errors,
        ...err.graphQLErrors[0].extensions.errors,
      }),
  });
  const [editContacto] = useMutation(EDIT_CONTACTO_MUTATION, {
    variables: { contacto: formData },
    onCompleted: () => callback(),
    onError: (err) =>
      setErrors({
        ...errors,
        ...err.graphQLErrors[0].extensions.errors,
      }),
  });

  const handleSubmit = () => {
    if (type === "ubicacion") {
      editUbicacion();
    } else {
      editContacto();
    }
  };

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => callback()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Box display="flex" justifyContent="center" flexDirection="column">
          <FormLabel>
            Edite {type === "ubicacion" ? "la ubicación" : "los contactos"}
          </FormLabel>
          <br />
          <br />
          <InputLabel id="ubicacion">
            {type === "ubicacion" ? "Ubicación" : "Contactos"}
          </InputLabel>
          <FormControl className={classes.formControl}>
            {type === ubicacion ? (
              <Select
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                input={<Input id="ubicacion" />}
                value={Ubicacion}
                onChange={(event) => setUbicacion(event.target.value)}
                multiple
                fullWidth
                required
                labelId="ubicacion"
                id="ubicacion"
                name="ubicacion"
              >
                <MenuItem value="Google_maps">Google maps</MenuItem>
                <MenuItem value="Waze">Waze</MenuItem>
              </Select>
            ) : (
              <Select
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                input={<Input id="ubicacion" />}
                value={Ubicacion}
                onChange={(event) => setUbicacion(event.target.value)}
                multiple
                fullWidth
                required
                labelId="ubicacion"
                id="ubicacion"
                name="ubicacion"
              >
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="Instagram">Instagram</MenuItem>
                <MenuItem value="Whatsapp">Whatsapp</MenuItem>
                <MenuItem value="Celular">Celular</MenuItem>
              </Select>
            )}
          </FormControl>
          {Ubicacion.map((local) => (
            <Fragment key={local}>
              <br />
              <br />
              <FormControl>
                {type === "ubicacion" && (
                  <Fragment>
                    <FormLabel>
                      Debe ingresar el link de la dirección de su
                      establecimiento en {local}. Puede buscar su dirección{" "}
                      <a
                        href={
                          local === "Google_maps"
                            ? "https://www.google.com/maps/?hl=es"
                            : "https://www.waze.com/es/live-map"
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        aqui
                      </a>
                    </FormLabel>
                    <br />
                  </Fragment>
                )}
                <TextField
                  name={local}
                  variant="outlined"
                  required
                  fullWidth
                  value={formData[local]}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      [local]: event.target.value,
                    })
                  }
                  id={local}
                  label={local}
                />
              </FormControl>
            </Fragment>
          ))}
          {Object.keys(errors).length !== 0 && (
            <Fragment>
              <br />
              <br />

              <HighlightedInformation>
                {Object.values(errors).map((err, i, array) => {
                  return (
                    <Fragment key={i}>
                      {`*${err}`} <br /> {array.length - 1 !== i ? <br /> : ""}
                    </Fragment>
                  );
                })}
              </HighlightedInformation>
            </Fragment>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <ColorButton
          className={classes.borderMinus}
          onClick={() => callback(type)}
          color="default"
        >
          Cancelar
        </ColorButton>
        <ColorButton
          className={classes.borderMinus}
          onClick={handleSubmit}
          color="default"
          autoFocus
        >
          Cambiar
        </ColorButton>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Ubicacion));
