import React, { memo } from "react";
import { useQuery, gql } from "@apollo/client";
import { Box, Typography, withStyles } from "@material-ui/core";
import Carousel from "react-elastic-carousel";
import hostBack from "../../../config";
import Nothing from "../../../errors/Nothing";
import Loading from "../../../errors/Loading";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  titulo: {
    fontSize: "1.45em",
  },
  img: {
    borderRadius: 18,
    width: "100%",
    userDrag: "none",
  },
  caja: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  principal: {
    fontSize: "2.50em",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  heightFixed: {
    height: "100px",
  },
});

const GET_CATEGORIAS = gql`
  query {
    getCategorias {
      id
      nombre
      foto
    }
  }
`;

function Categorias(props) {
  const { classes } = props;
  const { data, loading, error } = useQuery(GET_CATEGORIAS);
  const matches = useMediaQuery("(min-width:600px)");

  const settings = {
    pagination: matches,
    enableAutoPlay: true,
    autoPlaySpeed: 3500,
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 850, itemsToShow: 5 },
  ];

  if (loading) return <Loading min={true} topPad={true} />;
  if (error)
    return <Nothing text={`Error! ${error.message}`} top={true} min={true} />;

  return (
    <Box
      display="flex"
      justifyContent="center"
      preventDefaultTouchmoveEvent={true}
      alignItems={data.getCategorias.length > 0 ? "flex-end" : "center"}
      p={6}
      className="lg-p-bottom"
    >
      <div style={{ width: "100%" }}>
        <Typography align="center" variant="h2" className={classes.principal}>
          Todas las categorias para tu diversión
        </Typography>
        <br />
        {data.getCategorias.length > 0 ? (
          <Carousel
            showArrows={matches}
            {...settings}
            breakPoints={breakPoints}
          >
            {data.getCategorias.map((categoria) => (
              <Box key={categoria.id} className={classes.caja}>
                <Link
                  to={`/categoria/${categoria.nombre}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "85%",
                  }}
                >
                  <img
                    src={hostBack + categoria.foto}
                    alt="Ciudad de demostración para la inclusión de busquedas de establecimientos en ciudades"
                    className={
                      matches
                        ? classes.img
                        : classNames(classes.img, classes.heightFixed)
                    }
                    height="200px"
                  />
                </Link>
                <Typography
                  align="center"
                  className={classes.titulo}
                  variant="h3"
                >
                  {categoria.nombre}
                </Typography>
              </Box>
            ))}
          </Carousel>
        ) : (
          <Nothing
            fullHeight={true}
            text="Lo sentimos aun no hemos creado ninguna categoria para mostrar"
          />
        )}
      </div>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Categorias));
