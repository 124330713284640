import React, { useState, useCallback, useRef, Fragment , useContext} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { TextField, Button, Typography, withStyles } from "@material-ui/core";
import FormDialog from "../../../shared/components/FormDialog";
import HighlightedInformation from "../../../shared/components/HighlightedInformation";
import ButtonCircularProgress from "../../../shared/components/ButtonCircularProgress";
import VisibilityPasswordTextField from "../../../shared/components/VisibilityPasswordTextField";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../../context/auth";
const LOGIN_USERS_MUTATION = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      
      id
      username
      email
      token
    }
  }
`;
const styles = (theme) => ({
  forgotPassword: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
  disabledText: {
    cursor: "auto",
    color: theme.palette.text.disabled,
  },
  formControlLabel: {
    marginRight: 0,
  },
});

function LoginDialog(props) {
  const { setStatus, classes, onClose, openChangePasswordDialog, status } =
    props;
  const {login: loginContext} = useContext(AuthContext)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isErrors, setIsErrors] = useState(null);
  const [loginUser, { loading: isLoading }] = useMutation(
    LOGIN_USERS_MUTATION,
    {
      variables: { ...formData },
      onCompleted: (data) => {

        loginContext(data.login);
        onClose();
      },
      onError: (err) => {

        if(err.graphQLErrors) {
          
          setIsErrors({
            ...(err.graphQLErrors[0].extensions.errors),
          })
        } else {
          setIsErrors({err})
        }
      }
    }
  );
  const handleChange = (ev) => {
    setFormData({ ...formData, [ev.target.name]: ev.target.value });
  };
  const loginEmail = useRef();
  const loginPassword = useRef();

  const login = useCallback(() => {
    setIsErrors(null)
    setStatus(null);

    loginUser();
  }, [loginUser, setStatus]);

  return (
    <Fragment>
      <FormDialog
        open
        onClose={onClose}
        loading={isLoading}
        onFormSubmit={(e) => {
          e.preventDefault();
          login();
        }}
        hideBackdrop
        headline="Iniciar sesión"
        content={
          <Fragment>
            <TextField
              variant="outlined"
              margin="normal"
              error={status === "invalidEmail"}
              required
              fullWidth
              value={formData.email}
              label="Correo electronico"
              inputRef={loginEmail}
              autoFocus
              autoComplete="off"
              name="email"
              type="email"
              onChange={(event) => {
                if (status === "invalidEmail") {
                  setStatus(null);
                }
                handleChange(event);
              }}
              helperText={
                status === "invalidEmail" &&
                "No se ha creado ninguna cuenta con este correo."
              }
              FormHelperTextProps={{ error: true }}
            />
            <VisibilityPasswordTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={formData.password}
              error={status === "invalidPassword"}
              label="Contraseña"
              name="password"
              inputRef={loginPassword}
              autoComplete="off"
              onChange={(event) => {
                if (status === "invalidPassword") {
                  setStatus(null);
                }
                handleChange(event);
              }}
              helperText={
                status === "invalidPassword" ? (
                  <span>
                    Contraseña incorrecta, intentelo nuevamente o de click en{" "}
                    <b>&quot;Forgot Password?&quot;</b> para reestablecerla.
                  </span>
                ) : (
                  ""
                )
              }
              FormHelperTextProps={{ error: true }}
              onVisibilityChange={setIsPasswordVisible}
              isVisible={isPasswordVisible}
            />
            {status === "verificationEmailSend" ? (
              <HighlightedInformation>
                Te hemos enviado algunas instrucciones al correo para que puedas
                recuperar la contraseña
              </HighlightedInformation>
            ) : (
              ""
            )}
            {isErrors && (
              <HighlightedInformation>
                {Object.values(isErrors).map((err, i, array) => (
                  <Fragment key={i}>
                    {`*${err}`} <br /> {array.length - 1 !== i ? <br /> : ""}
                  </Fragment>
                ))}
              </HighlightedInformation>
            )}
          </Fragment>
        }
        actions={
          <Fragment>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={isLoading}
              size="large"
            >
              Iniciar sesión
              {isLoading && <ButtonCircularProgress />}
            </Button>
            <Typography
              align="center"
              className={classNames(
                classes.forgotPassword,
                isLoading ? classes.disabledText : null
              )}
              color="primary"
              onClick={isLoading ? null : openChangePasswordDialog}
              tabIndex={0}
              role="button"
              onKeyDown={(event) => {
                // For screenreaders listen to space and enter events
                if (
                  (!isLoading && event.keyCode === 13) ||
                  event.keyCode === 32
                ) {
                  openChangePasswordDialog();
                }
              }}
            >
              Olvidaste tu contraseña?
            </Typography>
          </Fragment>
        }
      />
    </Fragment>
  );
}

LoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  openChangePasswordDialog: PropTypes.func.isRequired,
  status: PropTypes.string,
};

export default withRouter(withStyles(styles)(LoginDialog));
