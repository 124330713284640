import React, { memo } from "react";
import { useQuery, gql } from "@apollo/client";
import { Box, Typography, withStyles, Grid } from "@material-ui/core";
import Nothing from "../../../errors/Nothing";
import Loading from "../../../errors/Loading";
import hostBack from "../../../config";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  titulo: {
    fontSize: "1.45em",
  },
  img: {
    borderRadius: 18,
    width: "100%",
    userDrag: "none",
  },
  caja: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "12px",
  },
  principal: {
    fontSize: "2.50em",
  },
});

const GET_CATEGORIAS = gql`
  query {
    getCategorias {
      id
      nombre
      foto
    }
  }
`;

function CategoriaList(props) {
  const { classes } = props;
  const { data, loading, error } = useQuery(GET_CATEGORIAS);

  if (loading) return <Loading min={true} topPad={true} />;
  if (error)
    return <Nothing text={`Error! ${error.message}`} top={true} min={true} />;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      p={6}
      pt={12}
      className="lg-p-bottom "
    >
      <div style={{ width: "100%" }}>
        <Typography align="center" variant="h2" className={classes.principal}>
          Todas las categorias para tu diversión
        </Typography>
        <br />
        {data.getCategorias.length > 0 ? (
          <Grid container>
            {data.getCategorias.map((categoria) => (
              <Grid
                key={categoria.id}
                item
                lg={2}
                md={4}
                sm={6}
                xs={12}
                className={classes.caja}
              >
                <Link
                  to={`/categoria/${categoria.nombre}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "85%",
                  }}
                >
                  <img
                    src={hostBack + categoria.foto}
                    alt="Ciudad de demostración para la inclusión de busquedas de establecimientos en ciudades"
                    className={classes.img}
                    height="200px"
                  />
                </Link>
                <Typography
                  align="center"
                  className={classes.titulo}
                  variant="h3"
                >
                  {categoria.nombre}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ): <Nothing
        fullHeight={true}
        text="Lo sentimos aun no hemos creado ninguna categoria para mostrar"
      />}
      </div>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(memo(CategoriaList));
