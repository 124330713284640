import React, { useState } from 'react'
import { useMutation, gql } from "@apollo/client"

const ADD_PLAN_MUTATION = gql`
    mutation($plan: String! $newMain: String $mainFoto: Upload $nombre: String $newName: String $foto: Upload ) {
        updatePlan(plan: $plan newMain: $newMain mainFoto: $mainFoto nombre: $nombre newName: $newName foto: $foto) {
            id
            mainPlan
            subPlans {
               nombre
               foto
            }
            mainFoto
        }
    }
`

export default function AñadirPlanes() {
    const [variables, setVariables] = useState({ plan: "", newMain: "", nombre: "", newName: "" })
    const [subir] = useMutation(ADD_PLAN_MUTATION, {
        variables: variables,
        onCompleted: (data) => console.log(data),
        onError: (err) => console.log(err)
    })
    const handleStringInput = (ev) => {
        setVariables({ ...variables, [ev.target.name]: ev.target.value })
    }
    const handleFileInput = (ev) => {
        setVariables({ ...variables, [ev.target.name]: ev.target.files[0] })
    }
    const handleSubmit = () => {
        subir();
    }

    return (
        <div className="lg-p-top">
            <input name="plan" onChange={(event) => handleStringInput(event)} />
            <input name="newMain" onChange={(event) => handleStringInput(event)} />
            <input name="mainFoto" type="file" onChange={(event) => handleFileInput(event)} />
            <input name="nombre" onChange={(event) => handleStringInput(event)} />
            <input name="newName" onChange={(event) => handleStringInput(event)} />
            <input type="file" name="foto" onChange={(event) => handleFileInput(event)} />
            <button onClick={handleSubmit}>Subir</button>
        </div>
    )
}
