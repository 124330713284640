import React, { useEffect, useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../../context/auth";
const GET_NOTIFICACIONES_QUERY = gql`
  {
    getNotificacionesEstablecimiento {
      id
      to
      message
      title
      reserva {
        user {
          id
          username
          telefono
        }
        establecimiento {
          id
          name
        }
        createdAt
        status
      }
      from
      createdAt
      global {
        isEstablecimiento
      }
    }
  }
`;
const GET_NOTIFICACIONES_SUBSCRIPTION = gql`
  subscription {
    newNotificacionesEst {
      id
      to
      message
      title
      reserva {
        user {
          id
          username
          telefono
        }
        establecimiento {
          id
          name
        }
        createdAt
        status
      }
      from
      createdAt
      global {
        isEstablecimiento
      }
    }
  }
`;
const GET_ESTABLECIMIENTO_COUNT = gql`
   {
  getEstCount
}
`
export default function LoadNotificationsEst() {
  const { setNotifications, setError, setCount } = useContext(AuthContext);
  const { subscribeToMore, data, error } = useQuery(GET_NOTIFICACIONES_QUERY, {
    onCompleted: () => {
      setNotifications(data.getNotificacionesEstablecimiento);
    },
    onError: () => setError(error),
  });
  const {data: note} = useQuery(GET_ESTABLECIMIENTO_COUNT, {
    onCompleted: () => {
      setCount(note.getEstCount);
    },
    onError: (err) => setError(err),
  })
  useEffect(() => {
    let unsubscribe = subscribeToMore({
      document: GET_NOTIFICACIONES_SUBSCRIPTION,

      onError: (err) => setError(err),
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const subscriptionResponse = subscriptionData.data.newNotificacionesEst;
        const formatedPrev = prev.getNotificacionesEstablecimiento.filter(
          (not) => not.id !== subscriptionResponse.id
        );
        const newCache = Object.assign({}, formatedPrev, {
          getNotificacionesEstablecimiento: [
            subscriptionResponse,
            ...formatedPrev,
          ],
        });
        setNotifications(newCache.getNotificacionesEstablecimiento);

        return newCache;
      },
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeToMore]);
  return <div></div>;
}
