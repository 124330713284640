import React, { memo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Hidden,
  IconButton,
  withStyles,
  Typography,
  useMediaQuery,
  Badge,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import Logo from "../../../logo_navbar.png";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import NavigationDrawer from "../../../shared/components/NavigationDrawer";
import FavoriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import CategoryIcon from "@material-ui/icons/Category";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import { AuthContext } from "../../../context/auth";
import NotificationsIcon from "@material-ui/icons/Notifications";
import EventIcon from "@material-ui/icons/Event";
import gql from "graphql-tag";

import { useQuery } from "@apollo/client";
const styles = (theme) => ({
  appBar: {
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    fontSize: "2.7em",
    color: theme.palette.common.black,
  },
  Button: {
    color: theme.palette.common.black,
    "&:hover": {
      opacity: 0.9,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: theme.palette.common.ligthGray,
    borderRadius: 12,
    "& ::before": {
      content: "none",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuButtonText: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.common.white,
  },
  brandText: {
    fontFamily: "'Baloo Bhaijaan', cursive",
    fontWeight: 400,
  },
  noDecoration: {
    textDecoration: "none !important",
  },
  favoriteButton: {
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 34,
      color: "#000",
    },
  },
  icon: {
    [theme.breakpoints.down("md")]: {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.up("md")]: {
      color: theme.palette.common.black,
    },
  },
});
const GET_CITIES = gql`
  {
    getCities
  }
`;
function NavBar(props) {
  const {
    classes,
    openRegisterDialog,
    openLoginDialog,
    handleMobileDrawerOpen,
    handleMobileDrawerClose,
    openNotificionDialog,
    mobileDrawerOpen,
    selectedTab,
    openEvento,
  } = props;
  const {
    user,
    establecimiento,
    ciudad,
    changeCity,
    logout,
    count,
    notifications,
  } = useContext(AuthContext);
  const matches = useMediaQuery("(max-width:600px)");

  const { data, error, loading } = useQuery(GET_CITIES);
  const handleCityError = () => {
    changeCity("Bogotá");
    return "Bogotá";
  };

  const handleChange = (event) => {
    changeCity(event.target.value);
  };
  const establecimientoItems = [
    {
      name: "Notificaciones",
      icon: (
        <Badge
          color="primary"
          overlap="circle"
          badgeContent={
            notifications && count ? notifications.length - count : 0
          }
        >
          <NotificationsIcon className={classes.favoriteButton} />
        </Badge>
      ),
      isIcon: true,
      onClick: openNotificionDialog,
    },
    {
      name: "Codigo QR",
      link: "/codigoQR",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height={matches ? "18px" : "35px"}
          viewBox="0 0 24 24"
          width={matches ? "18px" : "35px"}
          fill={matches ? "#fff" : "#000"}
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g>
              <path d="M3,11h8V3H3V11z M5,5h4v4H5V5z" />
              <path d="M3,21h8v-8H3V21z M5,15h4v4H5V15z" />
              <path d="M13,3v8h8V3H13z M19,9h-4V5h4V9z" />
              <rect height="2" width="2" x="19" y="19" />
              <rect height="2" width="2" x="13" y="13" />
              <rect height="2" width="2" x="15" y="15" />
              <rect height="2" width="2" x="13" y="17" />
              <rect height="2" width="2" x="15" y="19" />
              <rect height="2" width="2" x="17" y="17" />
              <rect height="2" width="2" x="17" y="13" />
              <rect height="2" width="2" x="19" y="15" />
            </g>
          </g>
        </svg>
      ),
      isIcon: true,
    },
    {
      name: "editor",
      link: "/establecimiento/edit",
      icon: <HomeIcon className={classes.favoriteButton} />,
      isIcon: true,
    },
    {
      name: "Iniciar Evento",
      onClick: () => openEvento(),
      icon: <EventIcon className={classes.icon} />,
      isIcon: false,
    },
    {
      name: "Cerrar Sesión",
      onClick: () => logout(),
      icon: <ExitToAppIcon className={classes.icon} />,
      isIcon: false,
    },
  ];
  const usuariosItems = [
    {
      name: "Notificaciones",
      icon: (
        <Badge
          color="primary"
          overlap="circle"
          badgeContent={notifications ? notifications.length - count : 1}
        >
          <NotificationsIcon className={classes.favoriteButton} />
        </Badge>
      ),
      isIcon: true,
      onClick: openNotificionDialog,
    },
    {
      name: "Favoritos",
      link: "/favoritos",
      icon: <FavoriteIcon className={classes.favoriteButton} />,
      isIcon: true,
    },
    {
      name: "Categorias",
      link: "/categorias",
      icon: <CategoryIcon className={classes.icon} />,
      isIcon: false,
    },
    {
      name: "Cerrar Sesión",
      onClick: () => logout(),
      icon: <ExitToAppIcon className={classes.icon} />,
      isIcon: false,
    },
  ];
  const menuItems = [
    {
      name: "Categorias",
      link: "/categorias",
      icon: <CategoryIcon className={classes.icon} />,
      isIcon: false,
    },
    {
      name: "Registrarse",
      onClick: openRegisterDialog,
      icon: <HowToRegIcon className={classes.icon} />,
      isIcon: false,
    },
    {
      name: "Ingresar",
      onClick: openLoginDialog,
      icon: <LockOpenIcon className={classes.icon} />,
      isIcon: false,
    },
  ];
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box display="flex" alignItems="center">
            <Link to="/">
              <img
                src={Logo}
                alt="Desparchate"
                width={!matches ? "80px" : "60px"}
                height={!matches ? "50px" : "40px"}
                style={{ borderRadius: 20, "::before": { content: "none" } }}
              />
            </Link>
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <ErrorIcon />
            ) : (
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel
                  style={{ color: "#000000" }}
                  id="demo-simple-select-outlined-label"
                >
                  Ciudad
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={
                    ((ciudad !== null) && data.getCities.includes(ciudad) ) ? ciudad : handleCityError()
                  }
                  style={
                    matches
                      ? { borderRadius: "12px", fontSize: "12px" }
                      : { borderRadius: "12px" }
                  }
                  onChange={handleChange}
                  label="Ciudad"
                >
                  {data.getCities.length > 0 ? (
                    data.getCities.map((City) => {
                      return (
                        <MenuItem key={City} value={City}>
                          {City} y alrededores
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value="Bogotá">Bogotá y alrededores</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          </Box>
          <Hidden mdDown>
            <Box display="flex" alignItems="center">
              <Typography variant="h1" className={classes.title}>
                Desparchate
              </Typography>
            </Box>
          </Hidden>

          <Box
            style={{
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Hidden mdUp>
              <IconButton
                className={classes.menuButton}
                onClick={handleMobileDrawerOpen}
                aria-label="Open Navigation"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown>
              <Box display="flex" justify="flex-end" alignItems="center" mr={4}>
                {(establecimiento
                  ? establecimientoItems
                  : user
                  ? usuariosItems
                  : menuItems
                ).map((element) => {
                  if (element.link && element.isIcon) {
                    return (
                      <Box
                        key={element.name}
                        display="flex"
                        justify="center"
                        alignItems="center"
                        mr={3}
                      >
                        <Link
                          to={element.link}
                          className={classes.noDecoration}
                          style={{ display: "flex" }}
                          onClick={handleMobileDrawerClose}
                        >
                          {element.icon}
                        </Link>
                      </Box>
                    );
                  } else if (element.isIcon) {
                    return (
                      <Box
                        key={element.name}
                        display="flex"
                        justify="center"
                        onClick={element.onClick}
                        alignItems="center"
                        mr={3}
                        style={{ cursor: "pointer" }}
                      >
                        {element.icon}
                      </Box>
                    );
                  }
                  return (
                    <Box
                      key={element.name}
                      diplay="flex"
                      mr={3}
                      justify="flex-end"
                    >
                      {element.link && (
                        <Link
                          to={element.link}
                          className={classes.noDecoration}
                        >
                          <Button
                            size="small"
                            style={{ borderRadius: 12 }}
                            variant="contained"
                            onClick={element.onClick}
                            className={classes.Button}
                            startIcon={element.icon}
                          >
                            {element.name}
                          </Button>
                        </Link>
                      )}
                      {!element.link && (
                        <Button
                          size="small"
                          style={{ borderRadius: 12 }}
                          variant="contained"
                          onClick={element.onClick}
                          className={classes.Button}
                          startIcon={element.icon}
                        >
                          {element.name}
                        </Button>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        menuItems={
          establecimiento
            ? establecimientoItems
            : user
            ? usuariosItems
            : menuItems
        }
        anchor="right"
        open={mobileDrawerOpen}
        selectedItem={selectedTab}
        onClose={handleMobileDrawerClose}
      />
    </div>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleMobileDrawerOpen: PropTypes.func,
  handleMobileDrawerClose: PropTypes.func,
  mobileDrawerOpen: PropTypes.bool,
  selectedTab: PropTypes.string,
  openRegisterDialog: PropTypes.func.isRequired,
  openLoginDialog: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(memo(NavBar));
