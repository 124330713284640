import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Slide,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import hostBack from "../../../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Producto(props) {
  const { evento, setEventos } = props;

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setEventos({ open: false, evento: {} })}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">{evento.title}</Typography>
          <Typography paragraph>
            <strong>Establecimiento: </strong>
            <Link to={`/establecimientos/${evento.establecimiento.id}`}>
              {evento.establecimiento.name}
            </Link>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <img
          src={hostBack + evento.foto}
          width="100%"
          style={{ borderRadius: "8px" }}
          alt={evento.message}
        />
        <DialogContentText>{evento.message}</DialogContentText>

        <Typography align="right">
          Finaliza <strong>{dayjs(evento.finalDate).fromNow()}</strong>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
