import React, { memo, useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import AOS from "aos/dist/aos";
import { withStyles } from "@material-ui/core";
import NavBar from "./navigation/NavBar";
import Footer from "./footer/Footer";
import "aos/dist/aos.css";
import CookieRulesDialog from "./cookies/CookieRulesDialog";
import CookieConsent from "./cookies/CookieConsent";
import { AuthContext } from "../../context/auth";
import DialogSelector from "./register_login/DialogSelector";
// import Payments from "./register_login/Payments";
import Routing from "./Routing";
import smoothScrollTop from "../../shared/functions/smoothScrollTop";
import PoliticasCondiciones from "./footer/PoliticasCondiciones";
import LoadNotifications from "./home/LoadNotifications";
import LoadNotificationsEst from "./home/LoadNotificationsEst";
import MakeEvento from "../../establecimiento/MakeEvento";

AOS.init({ once: true });

const styles = (theme) => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    overflowX: "hidden",
  },
});

function Main(props) {
  const { classes } = props;
  const { user, establecimiento } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(null);
  const [afterRegister, setAfterRegister] = useState(false);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(null);
  const [eventoOpen, setEventoOpen] = useState(false);
  const [isCookieRulesDialogOpen, setIsCookieRulesDialogOpen] = useState(false);
  const [abrirTerminosYCondiciones, setAbrirTerminosYCondiciones] =
    useState(false);

  const selectHome = useCallback(() => {
    smoothScrollTop();
    document.title = "Desparcharte";
    setSelectedTab("Home");
  }, [setSelectedTab]);

  const openLoginDialog = useCallback(() => {
    setDialogOpen("login");
    setIsMobileDrawerOpen(false);
  }, [setDialogOpen, setIsMobileDrawerOpen]);

  const closeDialog = useCallback(() => {
    if (afterRegister) {
      setAfterRegister(false);
    }
    setDialogOpen(null);
  }, [setDialogOpen, setAfterRegister, afterRegister]);
  const openNotificionDialog = useCallback(() => {
    setDialogOpen("Notifications");
    setIsMobileDrawerOpen(false);
  }, [setDialogOpen, setIsMobileDrawerOpen]);

  const openRegisterDialog = useCallback(() => {
    setAfterRegister(true);
    setDialogOpen("register");
    setIsMobileDrawerOpen(false);
  }, [setDialogOpen, setIsMobileDrawerOpen]);

  const openTermsDialog = useCallback(() => {
    setDialogOpen("termsOfService");
  }, [setDialogOpen]);

  const handleMobileDrawerOpen = useCallback(() => {
    setIsMobileDrawerOpen(true);
  }, [setIsMobileDrawerOpen]);

  const handleMobileDrawerClose = useCallback(() => {
    setIsMobileDrawerOpen(false);
  }, [setIsMobileDrawerOpen]);

  const openChangePasswordDialog = useCallback(() => {
    setDialogOpen("changePassword");
  }, [setDialogOpen]);

  const handleCookieRulesDialogOpen = useCallback(() => {
    setIsCookieRulesDialogOpen(true);
  }, [setIsCookieRulesDialogOpen]);

  const handleCookieRulesDialogClose = useCallback(() => {
    setIsCookieRulesDialogOpen(false);
  }, [setIsCookieRulesDialogOpen]);
  const handlePoliticasDialogOpen = useCallback(() => {
    setAbrirTerminosYCondiciones(true);
  }, [setAbrirTerminosYCondiciones]);

  const handlePoliticasDialogClose = useCallback(() => {
    setAbrirTerminosYCondiciones(false);
  }, [setAbrirTerminosYCondiciones]);

  return (
    <div className={classes.wrapper}>
      {!isCookieRulesDialogOpen && (
        <CookieConsent
          handleCookieRulesDialogOpen={handleCookieRulesDialogOpen}
        />
      )}
      <DialogSelector
        openLoginDialog={openLoginDialog}
        dialogOpen={dialogOpen}
        afterRegister={afterRegister}
        onClose={closeDialog}
        openTermsDialog={openTermsDialog}
        openRegisterDialog={openRegisterDialog}
        openChangePasswordDialog={openChangePasswordDialog}
      />
      <CookieRulesDialog
        open={isCookieRulesDialogOpen}
        onClose={handleCookieRulesDialogClose}
      />
      <PoliticasCondiciones
        open={abrirTerminosYCondiciones}
        onClose={handlePoliticasDialogClose}
      />
      <NavBar
        selectedTab={selectedTab}
        selectTab={setSelectedTab}
        openNotificionDialog={openNotificionDialog}
        openLoginDialog={openLoginDialog}
        openRegisterDialog={openRegisterDialog}
        openEvento={() => setEventoOpen(true)}
        mobileDrawerOpen={isMobileDrawerOpen}
        handleMobileDrawerOpen={handleMobileDrawerOpen}
        handleMobileDrawerClose={handleMobileDrawerClose}
      />
      <Routing selectHome={selectHome} />
      <Footer
        openTermsDialog={openTermsDialog}
        handleCookieRulesDialogOpen={handleCookieRulesDialogOpen}
        handlePoliticasDialogOpen={handlePoliticasDialogOpen}
      />
      {establecimiento && eventoOpen && (
        <MakeEvento
          establecimiento={establecimiento}
          callback={setEventoOpen}
        />
      )}
      {user && <LoadNotifications />}
      {establecimiento && <LoadNotificationsEst />}
      {/*establecimiento && !establecimiento.customerID && (
        <Payments establecimiento={establecimiento} />
      )*/}
    </div>
  );
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(memo(Main));
