import React, { memo } from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import PropsRoute from "../../shared/components/PropsRoute";
import Home from "./home/Home";
import CategoriaList from "./home/CategoriaList";
import Plan from "./admin/Plan";
import AñadirPlanes from "./admin/AñadirPlanes";
import Planes from "../../establecimiento/Planes";
import Categoria from "../../establecimiento/Categoria";
import Establecimiento from "../../establecimiento/Establecimiento";
import QRcode from "../../establecimiento/QRcode";
import Favoritos from "./home/Favoritos";
function Routing(props) {
  const { selectHome } = props;

  return (
    <Switch>
      <PropsRoute exact path="/categorias" component={CategoriaList} />
      <PropsRoute path="/favoritos" component={Favoritos} />
      <PropsRoute path="/codigoQR" component={QRcode} />
      <PropsRoute
        path="/establecimiento/edit"
        edited={true}
        component={Establecimiento}
      />

      <PropsRoute path="/establecimientos/:id" component={Establecimiento} />
      <PropsRoute path="/categoria/:categoria" component={Categoria} />
      <PropsRoute path="/planes/:plan" component={Planes} />
      <PropsRoute path="/updatePlan" component={AñadirPlanes} />
      <PropsRoute path="/addPlan" component={Plan} />
      <PropsRoute path="/" component={Home} selectHome={selectHome} />
    </Switch>
  );
}

Routing.propTypes = {
  selectHome: PropTypes.func.isRequired,
};

export default memo(Routing);
