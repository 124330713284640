import React, { memo } from "react";
import {
  Grid,
  GridList,
  GridListTile,
  Typography,
  GridListTileBar,
  Box,
  Button,
  withStyles,
} from "@material-ui/core";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import ReceiptIcon from "@material-ui/icons/Receipt";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import hostBack from "../config";
import Nothing from "../errors/Nothing";

const styles = (theme) => ({
  img: {
    width: "100%",
    height: "100%",
    userDrag: "none",
  },

  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    fontSize: 50,
    marginRight: 20,
    cursor: "pointer",
  },
  redIcon: {
    fontSize: 50,
    marginRight: 20,
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
});
const RedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "56px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "0.8",
    },
  },
}))(Button);
function Principal(props) {
  const {
    classes,
    menu,
    callback,
    edited,
    matched,
    changeType,
    principalScreen,
    openDetail,
  } = props;

  return (
    <Grid container item xs={12} md={10}>
      <Grid item md={6} xs={12}>
        <Typography variant="h3">Menú y servicios</Typography>
        <Typography variant="h4">
          {principalScreen.type.charAt(0).toUpperCase() +
            principalScreen.type.slice(1)}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box display="flex">
          {(edited || menu.alimentos.length > 0) && (
            <LocalDiningIcon
              onClick={() =>
                changeType({ ...principalScreen, type: "alimentos" })
              }
              className={
                principalScreen.type === "alimentos"
                  ? classes.redIcon
                  : classes.icon
              }
            />
          )}
          {(edited || menu.bebidas.length > 0) && (
            <LocalDrinkIcon
              onClick={() =>
                changeType({ ...principalScreen, type: "bebidas" })
              }
              className={
                principalScreen.type === "bebidas"
                  ? classes.redIcon
                  : classes.icon
              }
            />
          )}
          {(edited || menu.servicios.length > 0) && (
            <ReceiptIcon
              onClick={() =>
                changeType({ ...principalScreen, type: "servicios" })
              }
              className={
                principalScreen.type === "servicios"
                  ? classes.redIcon
                  : classes.icon
              }
            />
          )}
          {(edited || menu.complementos.length > 0) && (
            <AddCircleOutlineIcon
              onClick={() =>
                changeType({ ...principalScreen, type: "complementos" })
              }
              className={
                principalScreen.type === "complementos"
                  ? classes.redIcon
                  : classes.icon
              }
            />
          )}
        </Box>
      </Grid>
      <Box pt={3} width="100%">
        {menu[principalScreen.type] && menu[principalScreen.type].length > 0 ? (
          <GridList cellHeight={matched ? 120 : 360} cols={matched ? 2 : 3}>
            {menu[principalScreen.type].map((tile) => (
              <GridListTile
                onClick={() => openDetail({ open: true, producto: tile })}
                key={tile.nombre}
                style={{ cursor: "pointer" }}
                cols={tile.cols || 1}
              >
                <img
                  src={hostBack + tile.foto}
                  className={classes.img}
                  alt={tile.nombre}
                />
                <GridListTileBar
                  titlePosition="top"
                  title={tile.nombre}
                  className={classes.titleBar}
                />
              </GridListTile>
            ))}
          </GridList>
        ) : (
          <Nothing text="Todavia no hay ningún producto añadido" />
        )}

        {edited && (
          <Box display="flex" pt={3} justifyContent="center">
            <RedButton onClick={() => callback(true)}>Añadir</RedButton>
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Principal));
