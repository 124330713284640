import React, { memo, useState, Fragment, useCallback } from "react";
import {
  TextField,
  FormLabel,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  withStyles,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import HighlightedInformation from "../..//shared/components/HighlightedInformation";

const EDIT_DESCRIPTION_MUTATION = gql`
  mutation ($descripcion: String!) {
    updateEstablecimiento(
      establecimientoUpdate: { descripcion: $descripcion }
    ) {
      id
      descripcion
    }
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
  },
  whiteInput: {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  borderMinus: {
    borderRadius: 14,
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: theme.palette.common.ligthGray,
    minHeight: "56px",
    "&:hover": {
      backgroundColor: theme.palette.common.darkGray,
    },
  },
}))(Button);

function Descripcion(props) {
  const { classes, callback, descripcion } = props;
  const [errors, setErrors] = useState({});
  const [newDesc, setNewDesc] = useState(descripcion);
  const [editDescripcion] = useMutation(EDIT_DESCRIPTION_MUTATION, {
    variables: { descripcion: newDesc },
    onCompleted: () => callback(false),
    onError: (err) =>
      setErrors({
        ...errors,
        ...err.graphQLErrors[0].extensions.errors
      }),
  });

  const handleChange = useCallback((ev) =>{
    setNewDesc(ev.target.value);
  }, [setNewDesc]);
  const handleSubmit = () => {
    editDescripcion();
  };

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => callback(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className={classes.dialog}>
        <FormLabel className="text-white">
          Edite el texto de su descripción
        </FormLabel>
        <TextField
          variant="filled"
          required
          multiline
          className={classes.whiteInput}
          fullWidth
          onChange={handleChange}
          value={newDesc}
          id="descripcion"
          label="Descripcion"
          name="descripcion"
        />
        {Object.keys(errors).length !== 0 && (
          <Fragment>
            <br/>
            <br/>
            
            <HighlightedInformation>
              {errors.messageObject.keys(errors).map((err, i, array) => {
                return (
                  <Fragment key={i}>
                    {`*${err}`} <br /> {array.length - 1 !== i ? <br /> : ""}
                  </Fragment>
                );
              })}
            </HighlightedInformation>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions className={classes.dialog}>
        <ColorButton
          className={classes.borderMinus}
          onClick={() => callback(false)}
          color="default"
        >
          Cancelar
        </ColorButton>
        <ColorButton
          className={classes.borderMinus}
          onClick={handleSubmit}
          color="default"
          autoFocus
        >
          Cambiar
        </ColorButton>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Descripcion));
