import React, {memo} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  withStyles,
  Button,
  Slide,
} from "@material-ui/core";
import hostback from "../../config";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const styles = (theme) => ({
    
    borderMinus: {
      borderRadius: 14,
    }
  });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DELETE_IMG_MUTATION = gql`
  mutation ($nombre: String! $tipo: String!) {
    deleteImageFromEstablecimiento(nombre: $nombre, tipo: $tipo) {
      id
      alimentos {
        foto
        nombre
        precio
        descripcion
      }
      bebidas {
        precio
        nombre
        foto
        descripcion
      }
      complementos {
        precio
        nombre
        foto
        descripcion
      }
      servicios {
        precio
        nombre
        foto
        descripcion
      }
    }
  }
`;

const RedButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    minHeight: "56px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: "0.8",
    },
  },
}))(Button);
function Producto(props) {
  const { classes, detalle, setDetalle, edited, tipo } = props;
  const [deleteImg] = useMutation(DELETE_IMG_MUTATION, {
    variables: { nombre: detalle.producto.foto, tipo },
    onCompleted: () => setDetalle({ open: false, producto: {} }),
    onError: (err) => alert(err)
  });

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setDetalle({ open: false, producto: {} })}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{detalle.producto.nombre}</DialogTitle>
      <DialogContent>
        <img
          src={hostback + detalle.producto.foto}
          width="100%"
          style={{ borderRadius: "8px" }}
          alt={detalle.producto.descripcion}
        />
        <DialogContentText>{detalle.producto.descripcion}</DialogContentText>
        <Typography align="right">precio: {detalle.producto.precio}</Typography>
      </DialogContent>
      {edited && (
        <DialogActions>
          <RedButton
            className={classes.borderMinus}
            onClick={deleteImg}
            color="default"
            autoFocus
          >
            Borrar
          </RedButton>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Producto));