import React, { memo, useState, Fragment, useCallback } from "react";
import {
  TextField,
  FormLabel,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  withStyles,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { DropzoneArea } from "material-ui-dropzone";
import NumberFormat from "react-number-format";
import HighlightedInformation from "../../shared/components/HighlightedInformation";

const EDIT_DESCRIPTION_MUTATION = gql`
  mutation (
    $alimentos: inputServicio
    $bebidas: inputServicio
    $complementos: inputServicio
    $servicios: inputServicio
  ) {
    addImageToEstablecimiento(
      alimentos: $alimentos
      bebidas: $bebidas
      complementos: $complementos
      servicios: $servicios
    ) {
      id
      alimentos {
        precio
        nombre
        foto
        descripcion
      }
      bebidas {
        precio
        nombre
        foto
        descripcion
      }
      complementos {
        precio
        nombre
        foto
        descripcion
      }
      servicios {
        precio
        nombre
        foto
        descripcion
      }
    }
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
  },
  whiteInput: {
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  borderMinus: {
    borderRadius: 14,
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: "black",
    backgroundColor: theme.palette.common.ligthGray,
    minHeight: "56px",
    "&:hover": {
      backgroundColor: theme.palette.common.darkGray,
    },
  },
}))(Button);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

function UploadMenu(props) {
  const { classes, callback, type } = props;
  const [errors, setErrors] = useState({});
  const [newDesc, setNewDesc] = useState({
    foto: null,
    nombre: "",
    precio: "",
    descripcion: "",
  });
  const [editDescripcion] = useMutation(EDIT_DESCRIPTION_MUTATION, {
    variables: { [type]: newDesc },
    onCompleted: () => callback(false),
    onError: (err) =>
      setErrors({
        ...errors,
        ...err.graphQLErrors[0].extensions.errors,
      }),
  });

  const handleChange = useCallback(
    (ev) => {
      setNewDesc({ ...newDesc, [ev.target.name]: ev.target.value });
    },
    [setNewDesc, newDesc]
  );
  const handleSubmit = () => {
    editDescripcion();
  };

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => callback(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className={classes.dialog}>
        <FormLabel className="text-white">
          Añada un producto a su menu
        </FormLabel>
        <DropzoneArea
          acceptedFiles={["image/*"]}
          dropzoneText={"Subir la imagen de su servicio"}
          onChange={(files) => setNewDesc({ ...newDesc, foto: files[0] })}
          filesLimit={1}
          showPreviewsInDropzone={true}
        />
        <br />
        <TextField
          variant="filled"
          required
          multiline
          className={classes.whiteInput}
          fullWidth
          onChange={handleChange}
          value={newDesc.nombre}
          InputLabelProps={{
            shrink: true,
          }}
          id="nombre"
          label="Nombre"
          name="nombre"
        />
        <br />
        <br />
        <TextField
          variant="filled"
          required
          className={classes.whiteInput}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          id="precio"
          label="Precio"
          name="precio"
        />
        <br />
        <br />
        <TextField
          variant="filled"
          required
          multiline
          className={classes.whiteInput}
          fullWidth
          onChange={handleChange}
          value={newDesc.descripcion}
          InputLabelProps={{
            shrink: true,
          }}
          id="descripcion"
          label="Descripcion"
          rowsMax={3}
          name="descripcion"
        />
        {Object.keys(errors).length !== 0 && (
          <Fragment>
            <br />
            <br />

            <HighlightedInformation>
              {Object.values(errors).map((err, i, array) => {
                return (
                  <Fragment key={i}>
                    {`*${err}`} <br /> {array.length - 1 !== i ? <br /> : ""}
                  </Fragment>
                );
              })}
            </HighlightedInformation>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions className={classes.dialog}>
        <ColorButton
          className={classes.borderMinus}
          onClick={() => callback(false)}
          color="default"
        >
          Cancelar
        </ColorButton>
        <ColorButton
          className={classes.borderMinus}
          onClick={handleSubmit}
          color="default"
          autoFocus
          disabled={newDesc.foto ? false : true}
        >
          Añadir
        </ColorButton>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles, { withTheme: true })(memo(UploadMenu));
