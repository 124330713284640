import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  withStyles,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ColoredButton from "../../../shared/components/ColoredButton";

const styles = (theme) => ({
  dialogActions: {
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialog: {
    zIndex: 1400,
  },
  backIcon: {
    marginRight: theme.spacing(1),
  },
});

function CookieRulesDialog(props) {
  const { classes, onClose, open, theme } = props;
  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={onClose}
      className={classes.dialog}
    >
      <DialogTitle> Nuestra política de cookies </DialogTitle>
      <DialogContent>
        <Typography variant="h6" color="primary" paragraph>
          ¿Qué son las cookies?
        </Typography>
        <Typography paragraph>
          Como es práctica común en casi todos los sitios web profesionales,
          este sitio utiliza cookies, que son pequeños archivos que se descargan
          en su computadora, para mejorar su experiencia. Esta página describe
          lo que información que recopilan, cómo la usamos y por qué a veces
          necesitamos almacenar estas cookies. También compartiremos cómo puede
          prevenir estos que las cookies no se almacenen, sin embargo, esto
          puede degradar o &apos; romper &apos; ciertos elementos de la
          funcionalidad de los sitios. Para más información general sobre las
          cookies, consulte el artículo de Wikipedia sobre HTTP Galletas.
        </Typography>
        <Typography variant="h6" color="primary" paragraph>
          Cómo usamos las cookies
        </Typography>
        <Typography paragraph>
          Usamos cookies por una variedad de razones que se detallan a
          continuación. Desafortunadamente En la mayoría de los casos, no
          existen opciones estándar de la industria para deshabilitar cookies
          sin deshabilitar completamente la funcionalidad y características
          añaden a este sitio. Se recomienda que deje en todas las cookies si no
          está seguro de si los necesita o no en caso de que se utilicen para
          proporcionar un servicio que utiliza.
        </Typography>
        <Typography variant="h6" color="primary" paragraph>
          Desactivación de cookies
        </Typography>
        <Typography paragraph>
          Puede evitar la configuración de cookies ajustando la configuración en
          su navegador (consulte la Ayuda de su navegador para saber cómo hacer
          esto). Sé consciente de deshabilitar las cookies afectará la
          funcionalidad de este y muchos otros sitios web que visita. La
          desactivación de las cookies suele resultar en deshabilitar ciertas
          funciones y características de este sitio. Por lo tanto, se recomienda
          que no desactive las cookies.
        </Typography>
        <Typography variant="h6" color="primary" paragraph>
          Las cookies que establecemos
        </Typography>
        <Typography paragraph> Cookies relacionadas con la cuenta: </Typography>
        <Typography paragraph>
          Si crea una cuenta con nosotros, utilizaremos cookies para gestión del
          proceso de alta y administración general. Estas Las cookies
          generalmente se eliminarán cuando cierre la sesión; sin embargo, en
          algunos casos pueden permanecer después para recordar las preferencias
          de su sitio cuando desconectado. Usamos cookies para recordar que
          aceptó este mensaje.
        </Typography>
        <Typography paragraph>
          {" "}
          Cookies relacionadas con el inicio de sesión:{" "}
        </Typography>
        <Typography paragraph>
          Usamos cookies cuando inicia sesión para que podamos recordar esto
          hecho. Esto evita que tenga que iniciar sesión cada vez que visite una
          nueva página. Por lo general, estas cookies se eliminan o borran
          cuando cierra la sesión para asegurarse de que solo puede acceder a
          funciones restringidas y áreas al iniciar sesión.
        </Typography>
        <Typography paragraph> Cookies de preferencias del sitio: </Typography>
        <Typography paragraph>
          Con el fin de brindarle una gran experiencia en este sitio,
          proporcionar la funcionalidad para establecer sus preferencias sobre
          cómo este sitio se ejecuta cuando lo usas. Para recordar sus
          preferencias, necesitamos configurar cookies para que se pueda acceder
          a esta información cada vez que interactuar con una página se ve
          afectado por sus preferencias.
        </Typography>
        <Typography variant="h6" color="primary" paragraph>
          Cookies de terceros:
        </Typography>
        <Typography paragraph>
          En algunos casos especiales también utilizamos cookies proporcionadas
          por terceros de confianza. fiestas. La siguiente sección detalla qué
          cookies de terceros usted puede encontrar a través de este sitio.
        </Typography>
        <Typography paragraph>
          Los análisis de terceros se utilizan para rastrear y medir el uso de
          este sitio. para que podamos seguir produciendo contenido atractivo.
          Estas cookies pueden realizar un seguimiento de cosas como el tiempo
          que pasa en el sitio o las páginas que visita lo que nos ayuda a
          comprender cómo podemos mejorar el sitio para usted.
        </Typography>
        <Typography variant="h6" color="primary" paragraph>
          Más información
        </Typography>
        <Typography paragraph>
          Ojalá te haya aclarado las cosas y como se hizo anteriormente
          mencionado si hay algo de lo que no está seguro si lo necesite o no,
          por lo general es más seguro dejar las cookies habilitadas en caso de
          interactúa con una de las funciones que utiliza en nuestro sitio. Esto
          La Política de cookies se creó con la ayuda de la Política de cookies
          Generador de plantillas y la plantilla de términos y condiciones.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ColoredButton
          onClick={onClose}
          variant="contained"
          color={theme.palette.common.black}
        >
          <ArrowBackIcon className={classes.backIcon} />
          atrás
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}

CookieRulesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CookieRulesDialog);
