import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import HeadSection from "./HeadSection";
import Blog from "../blog/Blog";
import Inicial from "./Inicial";
import Eventos from "./Eventos";
import Categorias from "./Categorias";
import Search from "./Search";
import Evento from "./Evento";

function Home(props) {
  const { selectHome, blogPosts, selectBlog } = props;
  const [searcher, setSearcher] = useState({ open: false, busqueda: "" });
  const handleChange = (ev) => {
    setSearcher({ ...searcher, busqueda: ev.target.value });
  };
  const [eventos, setEventos] = useState({ open: false, evento: {} });

  useEffect(() => {
    selectHome();
  }, [selectHome]);
  return (
    <Fragment>
      <Inicial
        busqueda={handleChange}
        buscar={() => setSearcher({ ...searcher, open: true })}
      />
      <Blog selectBlog={selectBlog} blogPosts={blogPosts} />
      <Categorias />
      <Eventos setEventos={setEventos} />
      <HeadSection />
      {searcher.open && (
        <Search
          busqueda={searcher.busqueda}
          onClose={() => setSearcher({ ...searcher, open: false })}
        />
      )}
      {eventos.open && (
        <Evento evento={eventos.evento} setEventos={setEventos} />
      )}
    </Fragment>
  );
}

Home.propTypes = {
  selectHome: PropTypes.func.isRequired,
};

export default Home;
